import React, { useEffect, useState } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function FileUploadTermination(props) {
    return(
        <div>
            <h2>Kündigung</h2>
            <p>Kündigungsschreibung</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
            <p>letzte 3 Gehaltsabrechnungen</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
            <p>Arbeitsvertrag</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
        </div>
    );
}
function FileUploadWarning(props) {
    return(
        <div>
            <h2>Abmahnung</h2>
            <p>Abmahnung</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
        </div>
    );
}
function FileUploadCertificate(props) {
    return(
        <div>
            <h2>Zeugnis</h2>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
        </div>
    );
}
function FileUploadSalary(props) {
    return(
        <div>
            <h2>Gehalt</h2>
            <p>Arbeitsvertrag</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
            <p>Zahlungsnachweis</p>
            <label>
                <input type="file" multiple name="files[]" />
            </label>
        </div>
    );
}
function FileUploadOther(props) {
    return(
        <div>
            <h2>Andere</h2>
        </div>
    );
}

function FileUpload(props) {
    const [agreeEmailContact, setAgreeEmailContact] = useState( false );
    const [agreePrivacyPolice, setAgreePrivacyPolice] = useState( false );
    const [agreeMandat, setAgreeMandat] = useState( false );
    const [agreeBekannt, setAgreeBekannt] = useState( false );
    const [finish, setFinish] = useState(false);
    props._bgColorHandler('upload');
    let content = "";

    const onChangeAcceptMail = (value) => {
        let formular = {
            acceptEmailCommunication: value
        }
        props._formularHandler(formular);
        setAgreeEmailContact( value );
    }

    const _formatDate = ( date ) => {
        if( !date ) { return date; }
        return date.split("-").reverse().join(".");
    }

    const _submitHandler = (e) => {
        e.preventDefault();
        var formElement = document.getElementById("finish-submit");
        var formData = new FormData( formElement );
        var url = 'https://dein-jobhelfer.de/api.php';
        var xhr = new XMLHttpRequest();
        console.log( formData );
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
              if( xhr.status === 200 ) {
                  setFinish( true );
              }
            }
        }
        xhr.open('POST', url, true);
        xhr.send(formData);
    }
    switch(props.formular.concernType) {
          case "Kuendigung":
              content = <FileUploadTermination props={props} />
              break;              
          case "Abmahnung":
              content = <FileUploadWarning props={props} />
              break;              
          case "Zeugnis":
              content = <FileUploadCertificate props={props} />
              break;              
          case "Gehalt":
              content = <FileUploadSalary props={props} />
              break;              
          case "Anderes":
              content = <FileUploadOther props={props} />
              break;              
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    if( finish ) {
        return( <Redirect push to={`${process.env.PUBLIC_URL}/finish`} /> );
    } else {
        return(
            <section className="djh-file-upload">
                <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_upload.svg`} alt="" title="" />
                <h1><small>Schritt 5 von 5</small>Daten-Upload</h1>
                <form id="finish-submit" onSubmit={(e) => _submitHandler(e)}>
                    <input type="hidden" name="form" value={JSON.stringify( props.formular )} />
                    <input type="hidden" name="formType" value="JOB" />
                    {content}
                    <div className="checkbox">
                            <input
                                type="checkbox"
                                value={agreeEmailContact}
                                onChange={(e) => setAgreeEmailContact( e.target.value === "true" ) }
                                id="agreeMail"
                            />
                            <label htmlFor="agreeMail" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ich bin mit der Kommunikation per E-Mail einverstanden
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value={agreePrivacyPolice}
                                onChange={(e) => setAgreePrivacyPolice( ( e.target.value === "true" ) )}
                                required
                                id="agreePrivacy"
                            />
                            <label htmlFor="agreePrivacy" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                <div>
                                Mit der Speicherung meiner Daten auf dieser Seite durch die Rechtsanwälte GERLING | SCHULZ | LÜDERS bin ich einverstanden. Die Ausführungen zum <Link to={`${process.env.PUBLIC_URL}/datenschutz`} title="Datenschutz" target="_blank">Datenschutz</Link> habe ich zur Kenntnis genommen.
                                </div>
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value={agreeMandat}
                                onChange={(e) => setAgreeMandat( ( e.target.value === "true") )}
                                required
                                id="agreePrivacy2"
                            />
                            <label htmlFor="agreePrivacy2" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                <div>
                                Ich habe zur Kenntnis genommen, dass ein Mandatsverhältnis mit den Rechtsanwälten GERLING | SCHULZ | LÜDERS erst mit Mandatsbestätigung durch die Rechtsanwälte GERLING | SCHULZ | LÜDERS zustande kommt.
                                </div>
                            </label>
                        </div>
                        <h2>Zusammenfassung</h2>
                        <div className="vhr-finish-overview">
                            {console.log( props.formular )}
                            <div>
                                <h3>Meine Daten</h3>
                                <div className="grid">
                                    <div>
                                        <dl>
                                            <dt>Vorname:</dt>
                                            <dd>{props.formular.firstname ? props.formular.firstname : "-"}</dd>
                                            <dt>Nachname:</dt>
                                            <dd>{props.formular.lastname ? props.formular.lastname : "-"}</dd>
                                            <dt>Telefon:</dt>
                                            <dd>{props.formular.phone ? props.formular.phone : "-"}</dd>
                                            <dt>E-Mail:</dt>
                                            <dd>{props.formular.mail ? props.formular.mail : "-"}</dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <dl>
                                            <dt>Straße:</dt>
                                            <dd>{props.formular.address.street ? props.formular.address.street : "-"}</dd>
                                            <dt>Hausnummer:</dt>
                                            <dd>{props.formular.address.number ? props.formular.address.number : "-"}</dd>
                                            <dt>Postleitzahl:</dt>
                                            <dd>{props.formular.address.postcode ? props.formular.address.postcode : "-"}</dd>
                                            <dt>Stadt:</dt>
                                            <dd>{props.formular.address.city ? props.formular.address.city : "-"}</dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <dl>
                                            <dt>Rechtschutzversicherung?</dt>
                                            <dd>{props.formular.legalProtectionInsurance ? "Ja" : "Nein"}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
    
                            <div>
                                <h3>Arbeitgeber</h3>
                                <div className="grid">
                                    <div>
                                        <dl>
                                            <dt>Firmenname:</dt>
                                            <dd>{props.formular.employer.firmname ? props.formular.employer.firmname : "-"}</dd>
                                            <dt>Arbeitsbeginn:</dt>
                                            <dd>{props.formular.employer.startOfContract ? props.formular.employer.startOfContract : "-"}</dd>
                                            
                                        </dl>
                                    </div>
                                    <div>
                                        <dl>
                                            <dt>Straße:</dt>
                                            <dd>{props.formular.employer.employerAddress.employerStreet ? props.formular.employer.employerAddress.employerStreet : "-"}</dd>
                                            <dt>Hausnummer:</dt>
                                            <dd>{props.formular.employer.employerAddress.employerNumber ? props.formular.employer.employerAddress.employerNumber : "-"}</dd>
                                            <dt>Postleitzahl:</dt>
                                            <dd>{props.formular.employer.employerAddress.employerPostcode ? props.formular.employer.employerAddress.employerPostcode : "-"}</dd>
                                            <dt>Stadt:</dt>
                                            <dd>{props.formular.employer.employerAddress.employerCity ? props.formular.employer.employerAddress.employerCity : "-"}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {props.formular.concernType === "Kuendigung" ? 
                                <div>
                                    <h3>Infos zu Kündigung</h3>
                                    <div>
                                        <div>
                                            <dl>
                                                <dt>Zeit der Kündigung</dt>
                                                <dd>{props.formular.dateOfTermination ? props.formular.dateOfTermination : ''}</dd>
                                                <dt>Mehr als 10 Angestellte:</dt>
                                                <dd>{props.formular.employer.moreThanTenEmployees === "Yes"  ? "Ja" : "Nein"}</dd>
                                                <dt>Betriebsrat?:</dt>
                                                <dd>{props.formular.employer.hasWorksCouncil === "Yes"  ? "Ja" : "Nein"}</dd>
                                                <dt>Haben Sie eine Abmahnung bekommen:</dt>
                                                <dd>{props.formular.employer.hasWarningLetter === "Yes"  ? "Ja" : "Nein"}</dd>
                                            </dl>
                                        </div>
                                        <div>
                                            <dl>
                                                <dt>Sind Sie Schwanger?</dt>
                                                <dd>{props.formular.pregnant === "Yes" ? "Ja" : "Nein"}</dd>
                                                <dt>Besitzen Sie einen Schwerbehindertenausweis?</dt>
                                                <dd>{props.formular.handicapped === "Yes"  ? "Ja" : "Nein"}</dd>
                                                <dt>Sind Sie im Betriebsrat?</dt>
                                                <dd>{props.formular.memberOfWorksCouncil === "Yes"  ? "Ja" : "Nein"}</dd>
                                                <dt>Sind Sie Datenschutzbeauftragte?</dt>
                                                <dd>{props.formular.dataPrivacyOfficer === "Yes"  ? "Ja" : "Nein"}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            : 
                            ""}
                            {props.formular.concernType === "Abmahnung" ? 
                            <div>
                                <h3>Infos zu Abmahnung</h3>
                                <dl>
                                    <dt>Hatten Sie ein Vorgespräch?</dt>
                                    <dd>{props.formular.warningPreTalk === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Ist das Ihre erste Abmahnung?</dt>
                                    <dd>{props.formular.firstWarningLetter === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Anzahl der Abmahnung?</dt>
                                    <dd>{props.formular.countOfWarningLetter ? props.formular.countOfWarningLetter : "-"}</dd>
                                    <dt>Abmahnung älter als ein Jahr?</dt>
                                    <dd>{props.formular.warningLetterOlderThenOneYear === "Yes" ? "Ja" : "Nein"}</dd>
                                </dl>
                            </div>
                            : 
                            ""}
                            {props.formular.concernType === "Gehalt" ? 
                            <div>
                                <h3>Gehalt</h3>
                                <dl>
                                    <dt>Wie viel Geld weniger</dt>
                                    <dd>{props.formular.missingMoney ? props.formular.missingMoney : "-"}</dd>
                                    <dt>Haben Sie eine Gehaltsabrechnung?</dt>
                                    <dd>{props.formular.hasPayroll === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Fehler Monat</dt>
                                    <dd>{props.formular.monthOfError ? props.formular.monthOfError : "-"}</dd>
                                </dl>
                            </div>
                            : 
                            ""}
                            {props.formular.concernType === "Zeugnis" ? 
                            <div>
                                <h3>Zeugnis</h3>
                                <dl>
                                    <dt>Zeugnis erhalten?</dt>
                                    <dd>{props.formular.certificateReceived === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Schreibfehler?</dt>
                                    <dd>{props.formular.certificateSpellingError === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Tätigkeitsbeschreibung unvollständig??</dt>
                                    <dd>{props.formular.certificateJobDescriptionError === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Schlechte Bewertung?</dt>
                                    <dd>{props.formular.certificateBadJudgement === "Yes" ? "Ja" : "Nein"}</dd>
                                    <dt>Versteckte Sprache?</dt>
                                    <dd>{props.formular.certificateHiddenLanguage === "Yes" ? "Ja" : "Nein"}</dd>
                                </dl>
                            </div>
                            : 
                            ""}
                            {props.formular.concernType === "Anderes" ? 
                            <div>
                                <h3>Anderes Anliegen</h3>
                                <p>{props.formular.otherIssue ? props.formular.otherIssue : "-"}</p>
                            </div>
                            : 
                            ""}
    
                            <div>
                                <h3>Anmerkung:</h3>
                                <p>{props.formular.additionalNote ? props.formular.additionalNote : "-"}</p>
                            </div>
                        </div>
                    <button type="submit">Daten absenden</button>
                </form>
            </section>
        );
    }
}
/*
function FileUpload(props) {
    const [finish, setFinish] = useState(false);
    const [agreeEmailContact, setAgreeEmailContact] = useState( false );
    const [agreePrivacyPolice, setAgreePrivacyPolice] = useState( false );
    const [agreeMandat, setAgreeMandat] = useState( false );
    const [agreeBekannt, setAgreeBekannt] = useState( false );
    props._bgColorHandler('CYAN');

    const onChangeAcceptMail = (value) => {
        let formular = {
            acceptEmailCommunication: value
        }
        props._formularHandler(formular);
        setAgreeEmailContact( value );
    }

    const _submitHandler = (e) => {
        e.preventDefault();
        var formElement = document.getElementById("finish-submit");
        var formData = new FormData( formElement );
        var url = 'https://www.dein-scheidungshelfer.de/api.php';
        var xhr = new XMLHttpRequest();
        
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
              if( xhr.status === 200 ) {
                  setFinish( true );
              }
            }
        }
        xhr.open('POST', url, true);
        xhr.send(formData);
    }

    const _formatDate = ( date ) => {
        if( !date ) { return date; }
        return date.split("-").reverse().join(".");
    }

    const _marriageCertificateMapping = ( item ) => {
        switch( item ) {
            case "upload-marriage-certificate": 
                return "lade ich hoch";
                break;
            case "resubmit-marriage-certificate":
                return "reiche ich nach";
                break;
        }
    }

    if( finish ) {
        return( <Redirect push to={`${process.env.PUBLIC_URL}/finish`} /> );
    }else {
        return(
        
            <section className="file-upload">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/freiwillige-informationen/vermoegensrechtlich`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <img src={`${process.env.PUBLIC_URL}/assets/images/scheidungshelfer_schritt_7.svg`} alt="" title="" />
                <h1><small>Schritt 7</small>Daten-Upload</h1>
                <p>
                    Laden Sie hier alle Ihnen vorliegenden Unterlagen zu Ihrer Scheidung hoch.
                    <small>z.B. Heiratsurkunde, Notarvertrag.</small>
                </p>
                <form id="finish-submit" onSubmit={(e) => _submitHandler(e)}>
                    <input type="hidden" name="form" value={JSON.stringify( props.formular )} />
                    <input type="hidden" name="formType" value="SCHEIDUNG" />
                    <input type="file" multiple name="files[]" />
                    <p>Alternativ können Sie uns die Unterlagen per E-Mail, Fax oder Post zusenden. Sie können die Unterlagen aber auch in der Kanzlei einwerfen oder vorbei bringen.</p>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            value={agreeEmailContact}
                            onChange={(e) => onChangeAcceptMail( e.target.checked) }
                            id="agreeMail"
                        />
                        <label htmlFor="agreeMail" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Ich bin mit der Kommunikation per E-Mail einverstanden
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            value={agreePrivacyPolice}
                            onChange={(e) => setAgreePrivacyPolice( ( e.target.value === "true") )}
                            required
                            id="agreePrivacy"
                        />
                        <label htmlFor="agreePrivacy" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            <div>
                            Mit der Speicherung meiner Daten auf dieser Seite durch die Rechtsanwälte GERLING | SCHULZ | LÜDERS bin ich einverstanden. Die Ausführungen zum <Link to={`${process.env.PUBLIC_URL}/datenschutz`} title="Datenschutz" target="_blank">Datenschutz</Link> habe ich zur Kenntnis genommen.
                            </div>
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            value={agreeMandat}
                            onChange={(e) => setAgreeMandat( ( e.target.value === "true") )}
                            required
                            id="agreePrivacy2"
                        />
                        <label htmlFor="agreePrivacy2" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            <div>
                            Ich habe zur Kenntnis genommen, dass ein Mandatsverhältnis mit den Rechtsanwälten GERLING | SCHULZ | LÜDERS erst mit Mandatsbestätigung durch die Rechtsanwälte GERLING | SCHULZ | LÜDERS zustande kommt.
                            </div>
                        </label>
                    </div>
                    <h2>Zusammenfassung</h2>
                    <div className="vhr-finish-overview">
                        {console.log( props.formular )}
                        <div>
                            <h3>Meine Daten</h3>
                            <div className="grid">
                                <div>
                                    <dl>
                                        <dt>Vorname:</dt>
                                        <dd>{props.formular.firstname ? props.formular.firstname : "-"}</dd>
                                        <dt>Nachname:</dt>
                                        <dd>{props.formular.lastname ? props.formular.lastname : "-"}</dd>
                                        <dt>Geburtstag:</dt>
                                        <dd>{props.formular.birthdate ? _formatDate( props.formular.birthdate ) : "-"}</dd>
                                        <dt>Nationalität:</dt>
                                        <dd>{props.formular.nationality ? props.formular.nationality : "-"}</dd>
                                        <dt>Telefon:</dt>
                                        <dd>{props.formular.phone ? props.formular.phone : "-"}</dd>
                                        <dt>E-Mail:</dt>
                                        <dd>{props.formular.mail ? props.formular.mail : "-"}</dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>Straße:</dt>
                                        <dd>{props.formular.address.street ? props.formular.address.street : "-"}</dd>
                                        <dt>Hausnummer:</dt>
                                        <dd>{props.formular.address.number ? props.formular.address.number : "-"}</dd>
                                        <dt>Postleitzahl:</dt>
                                        <dd>{props.formular.address.postcode ? props.formular.address.postcode : "-"}</dd>
                                        <dt>Stadt:</dt>
                                        <dd>{props.formular.address.city ? props.formular.address.city : "-"}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3>Ehepartner</h3>
                            <div className="grid">
                                <div>
                                    <dl>
                                        <dt>Vorname:</dt>
                                        <dd>{props.formular.spouse.spouseFirstname ? props.formular.spouse.spouseFirstname : "-"}</dd>
                                        <dt>Nachname:</dt>
                                        <dd>{props.formular.spouse.spouseLastname ? props.formular.spouse.spouseLastname : "-"}</dd>
                                        <dt>Geburtstag:</dt>
                                        <dd>{props.formular.spouse.spouseBirthdate ? _formatDate( props.formular.spouse.spouseBirthdate ) : "-"}</dd>
                                        <dt>Nationalität:</dt>
                                        <dd>{props.formular.spouse.spouseNationality ? props.formular.spouse.spouseNationality : "-"}</dd>
                                    </dl>
                                </div>
                                <div>
                                    <p>letzte gem. Anschrift</p>
                                    <dl>
                                        <dt>Straße:</dt>
                                        <dd>{props.formular.spouse.spouseAddress.spouseStreet ? props.formular.spouse.spouseAddress.spouseStreet : "-"}</dd>
                                        <dt>Hausnummer:</dt>
                                        <dd>{props.formular.spouse.spouseAddress.spouseNumber ? props.formular.spouse.spouseAddress.spouseNumber : "-"}</dd>
                                        <dt>Postleitzahl:</dt>
                                        <dd>{props.formular.spouse.spouseAddress.spousePostcode ? props.formular.spouse.spouseAddress.spousePostcode : "-"}</dd>
                                        <dt>Stadt:</dt>
                                        <dd>{props.formular.spouse.spouseAddress.spouseCity ? props.formular.spouse.spouseAddress.spouseCity : "-"}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3>Infos zur Trennung</h3>
                            <div className="grid">
                                <div>
                                    <dl>
                                        <dt>Zustimmung zur Scheidung:</dt>
                                        <dd>{props.formular.willingToDivorce ? props.formular.willingToDivorce : "-"}</dd>
                                        <dt>Trennungsdatum:</dt>
                                        <dd>{props.formular.divorceInformation.divorceDate ? _formatDate( props.formular.divorceInformation.divorceDate ) : "-"}</dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>Straße:</dt>
                                        <dd>{props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceStreet ? props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceStreet : "-"}</dd>
                                        <dt>Hausnummer:</dt>
                                        <dd>{props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceNumber ? props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceNumber : "-"}</dd>
                                        <dt>Postleitzahl:</dt>
                                        <dd>{props.formular.divorceInformation.beforeDivorceAddress.beforeDivorcePostcode ? props.formular.divorceInformation.beforeDivorceAddress.beforeDivorcePostcode : "-"}</dd>
                                        <dt>Stadt:</dt>
                                        <dd>{props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceCity ? props.formular.divorceInformation.beforeDivorceAddress.beforeDivorceCity : "-"}</dd>
                                    </dl>
                                </div>
                            </div>
                            <dl>
                                <dt>Wer ist ausgezogen?:</dt>
                                <dd>{props.formular.divorceInformation.divorceType ? props.formular.divorceInformation.divorceType : "-"}</dd>
                            </dl>
                        </div>

                        <div>
                            <h3>Infos zu Kindern</h3>
                            <dl>
                                <dt>Minderjährige Kinder:</dt>
                                <dd>{props.formular.hasUnderagedChildren ? "Ja" : "Nein"}</dd>
                                {console.log( props.formular.children.length )}
                                {props.formular.children.length > 0 ? 
                                    <>
                                    <dt>Kinder:</dt>
                                    <dd>&nbsp;</dd>  
                                    </>
                                : ""}
                            </dl>
                            <dl>
                                {props.formular.children.map( child => {
                                    return <><dt>{child.childName}</dt> <dd>{_formatDate( child.childBirthdate )}</dd></>
                                })}
                            </dl>
                            <dl>
                                <dt>Gemeinsames Sorgerecht:</dt>
                                <dd>{props.formular.sharedCustody ? props.formular.sharedCustody : "-"}</dd>
                                <dt>Wohnort der Kinder:</dt>
                                <dd>{props.formular.predominantlyLife ? props.formular.predominantlyLife : "-"}</dd>
                                <dt>Kindesumgang einvernehmlich:</dt>
                                <dd>{props.formular.childMaintenance ? props.formular.childMaintenance : "-"}</dd>
                                <dt>Kindesunterhalt geklärt?:</dt>
                                <dd>{props.formular.childScopeClarified ? props.formular.childScopeClarified : "-"}</dd>
                            </dl>
                        </div>

                        <div>
                            <h3>Freiwillige Angaben</h3>
                            <dl>
                                <dt>Regelung des Unterhalts?</dt>
                                <dd>{props.formular.voluntaryInformation.divorceAlimony ? props.formular.voluntaryInformation.divorceAlimony : "-"}</dd>
                                <dt>Vermögensrechtliche Regelung?</dt>
                                <dd>{props.formular.voluntaryInformation.propertyLaw ? props.formular.voluntaryInformation.propertyLaw : "-"}</dd>
                            </dl>
                        </div>

                        <div>
                            <h3>Ermittlung der Kosten</h3>
                            <dl>
                                <dt>Netto-Einkommen</dt>
                                <dd>{props.formular.netIncome ? props.formular.netIncome : "-"}</dd>
                                <dt>Netto-Einkommen des Partners</dt>
                                <dd>{props.formular.spouseNetIncome ? props.formular.spouseNetIncome : "-"}</dd>
                                <dt>Zahlung der Anwaltskosten?</dt>
                                <dd>{props.formular.payLegalFees ? props.formular.payLegalFees : "-"}</dd>
                            </dl>
                        </div>

                        <div>
                            <h3>Dokumente</h3>
                            <dl>
                                <dt>Waren Sie beim Notar?</dt>
                                <dd>{props.formular.notarialContract ? props.formular.notarialContract : "-"}</dd>
                                <dt>Heiratsurkunde vorhanden?</dt>
                                <dd>{props.formular.marriageCertificate ? _marriageCertificateMapping( props.formular.marriageCertificate ) : "-"}</dd>
                                <dt>Laufendes Gerichtsverfahren?</dt>
                                <dd>{props.formular.ongoingLawsuit ? props.formular.ongoingLawsuit : "-"}</dd>
                                <dt>Regelung zum Versorgungsausgleich</dt>
                                <dd>{props.formular.pensionRightsAdjustment ? props.formular.pensionRightsAdjustment : "-"}</dd>
                            </dl>
                        </div>
                    </div>
                    <button type="submit">Daten absenden</button>
                 </form>
                {/* 
                <Route exact path={`${process.env.PUBLIC_URL}/meine-daten/`}>
                    <PersonalName _formularHandler={props._formularHandler} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/meine-daten/anschrifft`}>
                    <PersonalAddress _formularHandler={props._formularHandler} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/meine-daten/rechtsversicherung`}>
                    <PersonalLegalInsurance _formularHandler={props._formularHandler} />
                </Route>
                }
            </section>
        );
    }
    
}*/

export default FileUpload;
