var COLORS = {
    VIOLET: '#867CD2',
    ORANGE: '#FCB88C',
    BLUE: '#548CFA',
    CYAN: '#C4FFFB',
    ROSE: '#FFA6A2',
    YELLOW: '#FFE89A',
    allgemein: '#867cd2',
    kuendigung: '#fcb88c',
    abmahnung: '#548cfa',
    zeugnis: '#c4fffb',
    gehalt: '#ffa6a2',
    anderes: '#ffe89a',
    upload: '#f1eeb2',
    team: '#fcb88c',
    faq: '#81abf4',
    kontakt: '#ffcb41',
    datenschutz: '#6aa3ad',
    impressum: '#ff824d', 
};

export default COLORS;