import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

export default function OtherIssue(props) {
    props._bgColorHandler('anderes');
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [otherIssue, setOtherIssue] = useState(props.formular.otherIssue || "");

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            otherIssue: otherIssue
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/allgemeine-daten/meine-daten/`} /> );
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/daten-upload/`} /> );
    } else {
        return(
            <section className="djh-other-issue">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_anderes_anliegen.svg`} alt="" title="" />
                <h1><small>Schritt 2 von 5</small>weitere Informationen</h1>
                <p>Im Arbeitsleben gibt es viele Schnittstellen und Problemfelder, seien es Befristungen, Rückkehr aus der Elternzeit, Versetzungen, Arbeitsanweisungen und vieles mehr. Nicht alles lässt sich in einem Schema erfassen.</p>
                <p>Daher möchten wir Ihnen die Gelegenheit geben uns ihre Fragen kurz zu schildern.</p>
                <p>Wir können dann zusammen mit Ihnen eine optimale Lösung erarbeiten.</p>
                <form onSubmit={_handleSubmit}>
                    <textarea value={otherIssue} onChange={(e) => setOtherIssue(e.target.value)}></textarea>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </section>
        );
    }
}