import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function TerminationInfos(props) {
    const [dateOfTermination, setDateOfTermination] = useState( props.formular.dateOfTermination || "" );
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            dateOfTermination: dateOfTermination
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }
    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/arbeitgeber/`} /> );
    }else {
        return(
            <div className="djh-termination-infos">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Infos zum Arbeitgeber</h1>
                <form onSubmit={_handleSubmit}>
                    <label>
                        Wann haben Sie die Kündigung erhalten?
                        <input
                            type="date"
                            value={dateOfTermination}
                            onChange={e => setDateOfTermination(e.target.value)}
                            placeholder="Zeit der Kündigung"
                        />
                    </label>
                    <button type="submit">Weiter</button>
                </form>
            </div>
        );
    }
}

function EmployerInformations(props) {
    const [moreThanTenEmployees, setMoreThanTenEmployees] = useState( props.formular.employer.moreThanTenEmployees || "no" );
    const [hasWorksCouncil, setHasWorksCouncil] = useState( props.formular.employer.hasWorksCouncil || "no" );
    const [hasWarningLetter, setHasWarningLetter] = useState( props.formular.employer.hasWarningLetter || "no" );
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            employer: {
                ...props.formular.employer,
                moreThanTenEmployees: moreThanTenEmployees,
                hasWorksCouncil: hasWorksCouncil,
                hasWarningLetter: hasWarningLetter
            }
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/persoenliche-umstaende/`} /> );
    } else {
        return(
            <div className="djh-employer-informations">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Kündigung</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Haben Sie mehr als 10 Kollegen?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="moreThanTenEmployees"
                                onChange={e => setMoreThanTenEmployees(e.target.value)}
                                id="moreThanTenEmployees-Yes"
                                checked={moreThanTenEmployees === "Yes"}
                            />
                            <label htmlFor="moreThanTenEmployees-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="moreThanTenEmployees"
                                onChange={e => setMoreThanTenEmployees(e.target.value)}
                                id="moreThanTenEmployees-No"
                                checked={moreThanTenEmployees === "No"}
                            />
                            <label htmlFor="moreThanTenEmployees-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Gibt es einen Betriebsrat?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="hasWorksCouncil"
                                onChange={e => setHasWorksCouncil(e.target.value)}
                                id="hasWorksCouncil-Yes"
                                checked={hasWorksCouncil === "Yes"}
                            />
                            <label htmlFor="hasWorksCouncil-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="hasWorksCouncil"
                                onChange={e => setHasWorksCouncil(e.target.value)}
                                id="hasWorksCouncil-No"
                                checked={hasWorksCouncil === "No"}
                            />
                            <label htmlFor="hasWorksCouncil-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Haben Sie vor der Kündigung eine Abmahnung erhalten?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="hasWarningLetter"
                                onChange={e => setHasWarningLetter(e.target.value)}
                                id="hasWarningLetter-Yes"
                                checked={hasWarningLetter === "Yes"}
                            />
                            <label htmlFor="hasWarningLetter-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="hasWarningLetter"
                                onChange={e => setHasWarningLetter(e.target.value)}
                                id="hasWarningLetter-No"
                                checked={hasWarningLetter === "No"}
                            />
                            <label htmlFor="hasWarningLetter-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function PersonalCircumstances(props) {
    const [pregnant, setPregnant] = useState(props.formular.pregnant || "no");
    const [handicapped, setHandicapped] = useState(props.formular.handicapped || "no");
    const [memberOfWorksCouncil, setMemberOfWorksCouncil] = useState(props.formular.memberOfWorksCouncil || "no");
    const [dataPrivacyOfficer, setDataPrivacyOfficer] = useState(props.formular.dataPrivacyOfficer || "no");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            pregnant: pregnant,
            handicapped: handicapped,
            memberOfWorksCouncil: memberOfWorksCouncil,
            dataPrivacyOfficer: dataPrivacyOfficer
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/besonderheiten/`} /> );
    } else {
        return(
            <div className="djh-personal-circumstances">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/arbeitgeber/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Persönliche Umstände</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Sind Sie Schwanger?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="pregnant"
                                onChange={e => setPregnant(e.target.value)}
                                id="pregnant-Yes"
                                checked={pregnant === "Yes"}
                            />
                            <label htmlFor="pregnant-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="pregnant"
                                onChange={e => setPregnant(e.target.value)}
                                id="pregnant-No"
                                checked={pregnant === "No"}
                            />
                            <label htmlFor="pregnant-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Haben Sie einen Schwerbehindertenausweis oder sind Sie gleichgestellt?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="handicapped"
                                onChange={e => setHandicapped(e.target.value)}
                                id="handicapped-Yes"
                                checked={handicapped === "Yes"}
                            />
                            <label htmlFor="handicapped-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="handicapped"
                                onChange={e => setHandicapped(e.target.value)}
                                id="handicapped-No"
                                checked={handicapped === "No"}
                            />
                            <label htmlFor="handicapped-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Sind Sie im Betriebsrat?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="memberOfWorksCouncil"
                                onChange={e => setMemberOfWorksCouncil(e.target.value)}
                                id="memberOfWorksCouncil-Yes"
                                checked={memberOfWorksCouncil === "Yes"}
                            />
                            <label htmlFor="memberOfWorksCouncil-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="memberOfWorksCouncil"
                                onChange={e => setMemberOfWorksCouncil(e.target.value)}
                                id="memberOfWorksCouncil-No"
                                checked={memberOfWorksCouncil === "No"}
                            />
                            <label htmlFor="memberOfWorksCouncil-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Sind Sie Datenschutzbeauftragt?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="dataPrivacyOfficer"
                                onChange={e => setDataPrivacyOfficer(e.target.value)}
                                id="dataPrivacyOfficer-Yes"
                                checked={dataPrivacyOfficer === "Yes"}
                            />
                            <label htmlFor="dataPrivacyOfficer-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="dataPrivacyOfficer"
                                onChange={e => setDataPrivacyOfficer(e.target.value)}
                                id="dataPrivacyOfficer-No"
                                checked={dataPrivacyOfficer === "No"}
                            />
                            <label htmlFor="dataPrivacyOfficer-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function TerminationData(props) {
    props._bgColorHandler('kuendigung');

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return(
        <section>
            <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_kuendigung.svg`} alt="" title="" />
            <Route exact path={`${process.env.PUBLIC_URL}/kuendigungs-informationen/`}>
                <TerminationInfos formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/kuendigungs-informationen/arbeitgeber/`}>
                <EmployerInformations formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/kuendigungs-informationen/persoenliche-umstaende/`}>
                <PersonalCircumstances formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
        </section>
    );
}

export default TerminationData;