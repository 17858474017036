import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function CertificateInformationFirst(props) {
    const [certificateReceived, setCertificateReceived] = useState( props.formular.certificate.certificateReceived || "" );
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            certificate: {
                ...props.formular.certificate,
                certificateReceived: certificateReceived
            }
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        if( certificateReceived === "No" ) {
            return ( <Redirect push to={`${process.env.PUBLIC_URL}/zeugnis-informationen/hinweis`} /> );
        } else {
            return ( <Redirect push to={`${process.env.PUBLIC_URL}/zeugnis-informationen/2`} /> );
        }
    } else {
        return(
            <div className="djh-certificate-information-first">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Zeugnis</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Haben Sie ein Zwischen-/Endzeugnis bekommen?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="certificateReceived"
                                onChange={e => setCertificateReceived(e.target.value)}
                                id="certificateReceived-Yes"
                                checked={certificateReceived === "Yes"}
                            />
                            <label htmlFor="certificateReceived-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="certificateReceived"
                                onChange={e => setCertificateReceived(e.target.value)}
                                id="certificateReceived-No"
                                checked={certificateReceived === "No"}
                            />
                            <label htmlFor="certificateReceived-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function CertificateInformationSecond(props) {
    const [certificateSpellingError, setCertificateSpellingError] = useState(props.formular.certificate.certificateSpellingError || "no");
    const [certificateJobDescriptionError, setCertificateJobDescriptionError] = useState(props.formular.certificate.certificateJobDescriptionError || "no");
    const [certificateBadJudgement, setCertificateBadJudgement] = useState(props.formular.certificate.certificateBadJudgement || "no");
    const [certificateHiddenLanguage, setCertificateHiddenLanguage] = useState(props.formular.certificate.certificateHiddenLanguage || "no");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            certificate: {
                ...props.formular.certificate,
                certificateSpellingError: certificateSpellingError,
                certificateJobDescriptionError: certificateJobDescriptionError,
                certificateBadJudgement: certificateBadJudgement,
                certificateHiddenLanguage: certificateHiddenLanguage
            }
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/besonderheiten/`} /> );
    } else {
        return(
            <div className="djh-certificate-information-second">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/zeugnis-informationen/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Zeugnis</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Enthält das Zeugnis Schreibfehler oder Fehler in den Daten?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="certificateSpellingError"
                                onChange={e => setCertificateSpellingError(e.target.value)}
                                id="certificateSpellingError-Yes"
                                checked={certificateSpellingError === "Yes"}
                            />
                            <label htmlFor="certificateSpellingError-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="certificateSpellingError"
                                onChange={e => setCertificateSpellingError(e.target.value)}
                                id="certificateSpellingError-No"
                                checked={certificateSpellingError === "No"}
                            />
                            <label htmlFor="certificateSpellingError-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Ist die Tätigkeitsbeschreibung unvollständig?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="certificateJobDescriptionError"
                                onChange={e => setCertificateJobDescriptionError(e.target.value)}
                                id="certificateJobDescriptionError-Yes"
                                checked={certificateJobDescriptionError === "Yes"}
                            />
                            <label htmlFor="certificateJobDescriptionError-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="certificateJobDescriptionError"
                                onChange={e => setCertificateJobDescriptionError(e.target.value)}
                                id="certificateJobDescriptionError-No"
                                checked={certificateJobDescriptionError === "No"}
                            />
                            <label htmlFor="certificateJobDescriptionError-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Finden Sie, dass Sie schlecht oder unfair beurteilt werden?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="certificateBadJudgement"
                                onChange={e => setCertificateBadJudgement(e.target.value)}
                                id="certificateBadJudgement-Yes"
                                checked={certificateBadJudgement === "Yes"}
                            />
                            <label htmlFor="certificateBadJudgement-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="certificateBadJudgement"
                                onChange={e => setCertificateBadJudgement(e.target.value)}
                                id="certificateBadJudgement-No"
                                checked={certificateBadJudgement === "No"}
                            />
                            <label htmlFor="certificateBadJudgement-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Fürchten Sie versteckte Zeugnissprache zu Ihrem Nachteil?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="certificateHiddenLanguage"
                                onChange={e => setCertificateHiddenLanguage(e.target.value)}
                                id="certificateHiddenLanguage-Yes"
                                checked={certificateHiddenLanguage === "Yes"}
                            />
                            <label htmlFor="certificateHiddenLanguage-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="certificateHiddenLanguage"
                                onChange={e => setCertificateHiddenLanguage(e.target.value)}
                                id="certificateHiddenLanguage-No"
                                checked={certificateHiddenLanguage === "No"}
                            />
                            <label htmlFor="certificateHiddenLanguage-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function CertificateInformationAlert() {
    return(
        <div className="djh-certificate-information-get">
            <h1><small>Schritt 2 von 5</small>Zeugnis</h1>
            <p>Sie sollten so schnell wie möglich ein Zeugnis verlangen, gern können wir das für Sie übernehmen.</p>
            <Link to={`${process.env.PUBLIC_URL}/besonderheiten/`}>Weiter</Link>
        </div>
    );
}

export default function CertificateInformation(props) {
    props._bgColorHandler('zeugnis');

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return(
        <section>
            <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_zeugnis.svg`} alt="" title="" />
            <Route exact path={`${process.env.PUBLIC_URL}/zeugnis-informationen/`}>
                <CertificateInformationFirst formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/zeugnis-informationen/2`}>
                <CertificateInformationSecond formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/zeugnis-informationen/hinweis`}>
                <CertificateInformationAlert formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
        </section>
    )
}