import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

export default function Home(props) {

    props._bgColorHandler('kuendigung');
    return(
        <section className="vrh-home djh-home">
      <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_startseite.png`} alt="" title="" />
      <h1>Willkommen bei dein-Jobhelfer.de, Ihrem Hilfsportal bei allen Fragen zum Arbeitsrecht.</h1>
      <p>
      Wir bieten Ihnen professionelle und schnelle Hilfe rund ums Arbeitsrecht. Genießen Sie alle
      Vorteile moderner und digitaler Bearbeitung Ihrer Anliegen. Hilfe im Arbeitsrecht und speziell im Kündigungsschutz aus erfahrener Anwaltshand. Digital aber vor Ort für
      </p>
      <p><b>Hennef / Bonn / Siegburg / Rhein-Sieg-Kreis / Oberbergischer Kreis / Westerwald & im Umland.</b></p>
      <p>
        Sie erhalten von uns eine umfassende Beratung zu allen relevanten Aspekten Ihres Falles.
      </p>
      <p>
        Füllen Sie in wenigen Minuten die nachfolgenden Felder aus. Sollten uns Informationen fehlen, melden wir uns umgehend bei Ihnen. 
      </p>
      <p>
      Wir erstellen dann für Sie z.B. eine individuelle Kündigungsschutzklage maßgeschneidert
für Ihr Anliegen, die wir sofort digital bei Gericht einreichen können. 
      </p>
      <p>
      Gerne können Sie uns kostenlos kontaktieren.
      </p>
      <Link to={`${process.env.PUBLIC_URL}/allgemeine-daten`} className="vrh-btn">
        Jetzt starten
        <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" >
          <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="02-Meine-Daten---Seite-1" transform="translate(-747.000000, -882.000000)" fill="#252C32">
              <g id="Group-2" transform="translate(665.000000, 871.000000)">
                <g id="Group">
                  <path d="M95.5612786,13.9001297 L92.7710155,11 L92.0609486,11.7367056 L94.706198,14.4811933 L82,14.4811933 L82,15.5188067 L94.706198,15.5188067 L92.0609486,18.2632944 L92.7710155,19 L95.5612786,16.0998703 C96.1462405,15.4922074 96.1462405,14.5077926 95.5612786,13.9001297 L95.5612786,13.9001297 Z" id="Style"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Link>
    </section>
    )
}