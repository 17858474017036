import React from "react";

export default function Privacy(props) {
    props._bgColorHandler('VIOLET');
    return(
        <section class="vrh-imprint">
            <h1>Datenschutz</h1>
            <h2>Direkte Verarbeitung personenbezogener Daten</h2>
            <p>Um Ihre Anfrage über Scheidungshelfer.de bearbeiten zu können, verarbeiten wir die personenbezogenen Daten, die Sie uns dort und im Rahmen der Mandatsbearbeitung übermitteln.</p>
            <p>Wenn Sie uns über die Betätigung des Buttons "unverbindlich absenden" oder „absenden“ Anfragen und / oder Informationen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular einschließlich der von Ihnen dort angegebenen Kontaktdaten und der von Ihnen hochgeladenen Dokumente bei uns gespeichert, damit wir Ihre Anfrage / Angelegenheit bearbeiten können.</p>
            <h2>Bitte beachten Sie, dass wir anonyme Anfragen nicht bearbeiten können.</h2>
            <p>Aufgrund gesetzlich vorgegebener berufsrechtlicher Pflichten für Rechtsanwälte und bestehender Steuergesetzgebung sowie weiterer gesetzlicher Vorgaben sind wir verpflichtet, Daten zur Erfüllung vom Gesetzgeber geschaffener Aufbewahrungspflichten länger zu speichern, als viele andere Berufszweige. Rechtsgrundlage für diese Art der Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1 S. 1 lit. b) DSGVO.</p>
            <p>Wenn Sie uns Ihre E-Mail-Adresse zur Verfügung gestellt haben, erhalten Sie von uns alle Informationen zum Mandat per E-Mail, <b>wenn Sie darin ausdrücklich eingewilligt haben. Die schriftliche Kommunikation wird dann über E-Mail abgewickelt. Die Einwilligung erteilen Sie, wenn Sie auf unserer Seite Scheidungshelfer.de den Button „Kommunikation per E-Mail“ angeklickt haben.</b> Diese Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie uns eine Nachricht an die o. g. Kontaktdaten senden.</p>
            <p>Wir speichern Ihre E-Mail solange, bis Sie der Verwendung Ihrer Daten widersprochen oder die Einwilligung widerrufen haben und keine Aufbewahrungspflichten einer Löschung entgegenstehen.</p>
            <h2>Wenn Sie die Einwilligung zur Nutzung Ihrer E-Mail nicht erteilt haben, erfolgt die Kommunikation per Briefpost und / oder Fax, wenn Sie uns die erforderlichen Daten zur Verfügung gestellt haben.</h2>
            <p>Sie haben nach der DSGVO:</p>
            <h3>1. Ein Recht auf Auskunft (Art. 15 DSGVO)</h3>
            <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob und welche Sie betreffende personenbezogenen Daten für welchen Zweck verarbeitet werden.</p>
            <h3>2. Recht auf Berichtigung (Art. 16 DSGVO)</h3>
            <p>Sollten die Sie betreffenden Daten nicht – oder nicht mehr - zutreffend sein, können Sie unter den Voraussetzungen des Art. 16 DSGVO eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen. Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</p>
            <h3>3. Recht auf Löschung (Art. 17 DSGVO)</h3>
            <p>Sie können die Löschung Ihrer personenbezogenen Daten verlangen, sofern eine der Bedingungen des Art. 17 DSGVO erfüllt ist. Ihr Anspruch auf Löschung kann aber z.B. dadurch eingeschränkt sein, dass wir die Daten zur Erfüllung gesetzlicher Aufbewahrungspflichten noch benötigen. Ihr Recht auf Löschung kann unter bestimmten Umständen gemäß den gesetzlichen Vorschriften (z.B. § 35 BDSG) eingeschränkt sein kann.</p>
            <h3>4. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h3>
            <p>Unter den Voraussetzungen des Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung verlangen, z.B. wenn Ihre Daten unrichtig sind oder Sie gegen die Verarbeitung Widerspruch eingelegt haben.</p>
            <h3>5. Recht auf Unterrichtung (Art. 19 DSGVO)</h3>
            <p>Liegen die Voraussetzungen des Art. 19 DSGVO vor, teilen wir die Berichtigung, Löschung oder Einschränkung der Verarbeitung mit.</p>
            <h3>6. Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h3>
            <p>Sind die Bedingungen des Art. 20 DSGVO erfüllt, haben Sie ein Recht darauf, die Sie betreffenden personenbezogenen Daten in einem gängigen, maschinenlesbaren Format von uns zu erhalten. Daneben können sie eine direkte Weiterleitung an einen anderen Verantwortlichen verlangen, sofern dieses technisch machbar ist.</p>
            <h3>7. Recht auf Widerspruch (Art. 21 DSGVO)</h3>
            <p>Sie können jederzeit in einer besonderen Situation der weiteren Verarbeitung ihrer personenbezogenen Daten nach Art. 6 Abs. 1 lit. e) oder f) DSGVO widersprechen. Wir stellen die Verarbeitung ein, sofern nicht eine gesetzliche Ausnahme greift. Beispielsweise dürfen wir die Verarbeitung fortsetzen, wenn zwingende schutzwürdige Gründe vorliegen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn dieser zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.</p>
            <h3>8. Kontaktdaten</h3>
            <p>
                Sie erreichen uns unter:<br/>
                Rechtsanwälte GERLING | SCHULZ | LÜDERS<br/>
                Frankfurter Straße 85<br/>
                53773 Hennef<br/>
                E-Mail: <a href="mailto:info@dein-scheidungshelfer.de" titel="Kontaktieren Sie uns">info@dein-scheidungshelfer.de</a><br/>
                Tel: 02242 / 933 10 70<br/>
                Fax: 02242 / 933 10 88<br/>
                Unser Datenschutzbeauftragter ist:<br/>
                Rechtsanwalt Oliver Schunck<br/>
                Rechtsanwälte GERLING | SCHULZ | LÜDERS<br/>
                Frankfurter Straße 85<br/>
                53773 Hennef<br/><br/>
                Sie können sich mit einer Beschwerde auch an die Aufsichtsbehörde wenden. Für uns zuständig ist:<br/><br/>
                Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br/>
                Kavalleriestr. 2-4<br/>
                40213 Düsseldorf<br/>
                Tel.: +49 211 38424-0<br/>
                Fax: +49 211 38424-10<br/>
                E-Mail: poststelle@ldi.nrw.de<br/><br/>

                Stand: 04.05.2020
            </p>
        </section>
    );
}