import React, {useEffect} from 'react';

function Finish(props) {
    props._bgColorHandler('VIOLET');
    
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return(
        <section className="djh-finish">
            <img src={`${process.env.PUBLIC_URL}/assets/images/finish.svg`} alt="" title="" />
            <h1>Fertig! <br/>Sie hören bald von uns.</h1>
        </section>
    )
}

export default Finish;
