import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function ConcernInformation(props) {
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [concernType, setConcernType] = useState(props.formular.concernType || "");

    const _handleSubmit = (e) => {
        e.preventDefault();
        if( concernType === "" ) { return; }
        let formular = {
            concernType: concernType
        }
        props._formularHandler(formular);
        setIsFormValidated(true);
    }

    if( isFormValidated ) {
        switch( props.formular.concernType ) {
            case "Kuendigung":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/`} /> )
                break;
            case "Abmahnung":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/abmahnungs-informationen/`} /> )
                break;
            case "Zeugnis":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/zeugnis-informationen/`} /> )
                break;
            case "Gehalt":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/gehalts-informationen/`} /> )
                break;
            case "Anderes":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/anderes-anliegen/`} /> )
                break;
        }
        return( <Redirect push to={`${process.env.PUBLIC_URL}/allgemeine-daten/meine-daten/`} /> );
    }else {
        return(
            <div className="djh-convern-informations">
                <h1><small>Schritt 1 von 5</small>Anliegen</h1>
                <p>Wie können wir Ihnen helfen?</p>
                <p>Es geht um...</p>
                <form onSubmit={_handleSubmit}>
                    <div className="checkbox">
                        <input
                            type="radio"
                            value="Kuendigung"
                            name="vehicle-type"
                            onChange={e => setConcernType(e.target.value)}
                            id="Kuendigung"
                            checked={concernType === "Kuendigung"}
                        />
                        <label htmlFor="Kuendigung" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Kündigung
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="radio"
                            value="Abmahnung"
                            name="vehicle-type"
                            onChange={e => setConcernType(e.target.value)}
                            id="Abmahnung"
                            checked={concernType === "Abmahnung"}
                        />
                        <label htmlFor="Abmahnung" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Abmahnung
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="radio"
                            value="Zeugnis"
                            name="vehicle-type"
                            onChange={e => setConcernType(e.target.value)}
                            id="Zeugnis"
                            checked={concernType === "Zeugnis"}
                        />
                        <label htmlFor="Zeugnis" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Zeugnis
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="radio"
                            value="Gehalt"
                            name="vehicle-type"
                            onChange={e => setConcernType(e.target.value)}
                            id="Gehalt"
                            checked={concernType === "Gehalt"}
                        />
                        <label htmlFor="Gehalt" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Gehalt
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="radio"
                            value="Anderes"
                            name="vehicle-type"
                            onChange={e => setConcernType(e.target.value)}
                            id="Anderes"
                            checked={concernType === "Anderes"}
                        />
                        <label htmlFor="Anderes" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Etwas ganz anderes
                        </label>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function PersonalName(props) {
    const [firstname, setFirstName] = useState( props.formular.firstname || "" );
    const [lastname, setLastName] = useState( props.formular.lastname || "" );
    const [mail, setMail] = useState( props.formular.mail || "" );
    const [phone, setPhone] = useState( props.formular.phone || "" );
    const [street, setStreet] = useState( props.formular.address.street || "" );
    const [number, setNumber] = useState( props.formular.address.number || "" );
    const [postcode, setPostcode] = useState( props.formular.address.postcode || "" );
    const [city, setCity] = useState( props.formular.address.city || "" );
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [legalProtectionInsurance, setLegalProtectionInsurance] = useState( props.formular.legalProtectionInsurance || false);
    var backURL = process.env.PUBLIC_URL;

    props._bgColorHandler('upload');

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            firstname: firstname,
            lastname: lastname,
            mail: mail,
            phone: phone,
            legalProtectionInsurance: legalProtectionInsurance,
            address: {
                street: street,
                number: number,
                city: city,
                postcode: postcode
            }
        }
        props._formularHandler(formular);
        setIsFormValidated(true);
    }
    
    if( props.formular.concernType === "Abmahnung" || props.formular.concernType === "Kuendigung" || props.formular.concernType === "Zeugnis" || props.formular.concernType === "Gehalt" ) {
        backURL = backURL+"/besonderheiten/";
    } else {
        backURL = backURL+"/anderes-anliegen/";
    }

    if (isFormValidated) {
        return (
            <Redirect push to={`${process.env.PUBLIC_URL}/allgemeine-daten/arbeitgeber/`} />
        )
    } else {
        return (
            <div className="djh-personal-data">
                <Link className="vhr-back-btn" to={backURL}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 4 von 5</small>Meine Daten</h1>
                <p style={{marginBottom: "40px", maxWidth: "400px"}}>Die personenbezogenen Daten werden im Rahmen Ihrer Anfrage vertraulich behandelt. Innerhalb von 24h melden wir uns persönlich für den weiteren Austausch bei Ihnen!</p>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <label>
                            <input
                                type="text"
                                value={firstname}
                                onChange={e => setFirstName(e.target.value)}
                                required
                                placeholder="Vorname"
                            />
                        </label>
                        <label>
                            <input
                                type="text"
                                value={lastname}
                                onChange={e => setLastName(e.target.value)}
                                required
                                placeholder="Nachname"
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="text"
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                                placeholder="Straße"
                            />
                        </label>
                        <label>
                            <input
                                type="text"
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                                placeholder="Hausnummer"
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="number"
                                value={postcode}
                                onChange={e => setPostcode(e.target.value)}
                                placeholder="Postleitzahl"
                            />
                        </label>
                        <label>
                            <input
                                type="text"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                placeholder="Stadt"
                            />
                        </label>
                    </div>
                    <label>
                        <input
                            type="mail"
                            value={mail}
                            onChange={e => setMail(e.target.value)}
                            required
                            placeholder="E-Mail"
                        />
                    </label>
                    <label>
                        <input
                            type="tel"
                            /*pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"*/
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            //required
                            placeholder="Telefon"
                        />
                    </label>
                    <p>Ich habe eine Rechtschutzversicherung</p>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            value="Kuendigung"
                            name="vehicle-type"
                            onChange={e => setLegalProtectionInsurance(true)}
                            id="Kuendigung"
                            checked={legalProtectionInsurance}
                        />
                        <label htmlFor="Kuendigung" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Ja
                        </label>
                    </div>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            value="Kuendigung"
                            name="vehicle-type"
                            onChange={e => setLegalProtectionInsurance(false)}
                            id="Kuendigung"
                            checked={!legalProtectionInsurance}
                        />
                        <label htmlFor="Kuendigung" className="checkbox__label">
                            <span className="checkbox__icon"></span>
                            Nein
                        </label>
                    </div>
                    <input type="submit" value="Weiter" />
                </form>
            </div>
        )
    }
}

function EmployerInformations(props) {
    const [firmname, setFirmname] = useState( props.formular.employer.firmname || "" );
    const [street, setStreet] = useState( props.formular.employer.employerAddress.employerStreet || "" );
    const [number, setNumber] = useState( props.formular.employer.employerAddress.employerNumber || "" );
    const [postcode, setPostcode] = useState( props.formular.employer.employerAddress.employerPostcode || "" );
    const [city, setCity] = useState( props.formular.employer.employerAddress.employerCity || "" );
    const [startOfContract, setStartOfContract] = useState( props.formular.employer.startOfContract || "" );
    const [isFormValidated, setIsFormValidated] = useState(false);

    props._bgColorHandler('upload');

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            employer: {
                firmname: firmname,
                startOfContract: startOfContract,
                employerAddress: {
                    employerCity: city,
                    employerStreet: street,
                    employerNumber: number,
                    employerPostcode: postcode
                }
            }
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/daten-upload/`} /> )
        switch( props.formular.concernType ) {
            case "Kuendigung":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/kuendigungs-informationen/`} /> )
                break;
            case "Abmahnung":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/abmahnungs-informationen/`} /> )
                break;
            case "Zeugnis":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/zeugnis-informationen/`} /> )
                break;
            case "Gehalt":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/gehalts-informationen/`} /> )
                break;
            case "Anderes":
                return ( <Redirect push to={`${process.env.PUBLIC_URL}/anderes-anliegen/`} /> )
                break;
        }
    } else {
        return (
            <div className="djh-employer-data section">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten/meine-daten/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 4 von 5</small>Infos zum Arbeitgeber</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <label>
                            <input
                                type="text"
                                value={firmname}
                                onChange={e => setFirmname(e.target.value)}
                                required
                                placeholder="Firmenname"
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="text"
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                                placeholder="Straße"
                            />
                        </label>
                        <label>
                            <input
                                type="text"
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                                placeholder="Hausnummer"
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="number"
                                value={postcode}
                                onChange={e => setPostcode(e.target.value)}
                                placeholder="Postleitzahl"
                            />
                        </label>
                        <label>
                            <input
                                type="text"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                placeholder="Stadt"
                            />
                        </label>
                    </div>
                    <label>
                        Ich bin bei dem Arbeitgeber beschäftigt seit:
                        <input
                            type="date"
                            /*pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"*/
                            value={startOfContract}
                            onChange={e => setStartOfContract(e.target.value)}
                            //required
                            placeholder="Beschäftigungs beginn"
                        />
                    </label>
                    <input type="submit" value="Weiter" />
                </form>
            </div>
        )
    }
}

function GeneralData(props) {
    props._bgColorHandler('allgemein');

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return(
        <section>
            <Route exact path={`${process.env.PUBLIC_URL}/allgemeine-daten/`}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_allgemeine_daten.svg`} alt="" title="" />
                <ConcernInformation formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/allgemeine-daten/meine-daten/`}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_allgemeine_daten_2.svg`} alt="" title="" />
                <PersonalName 
                    formular={props.formular} 
                    _formularHandler={props._formularHandler} 
                    _bgColorHandler={props._bgColorHandler}
                />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/allgemeine-daten/arbeitgeber/`}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_allgemeine_daten_2.svg`} alt="" title="" />
                <EmployerInformations 
                    formular={props.formular} 
                    _formularHandler={props._formularHandler} 
                    _bgColorHandler={props._bgColorHandler}
                />
            </Route>
        </section>
    )
}

export default GeneralData;
