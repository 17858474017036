import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

var table = [
    {
        "value": "500",
        "1.0": "49.00",
        "1.2": "58.80",
        "1.3": "63.70",
        "1.5": "73.50"
    },
    {
        "value": "1000",
        "1.0": "88.00",
        "1.2": "105.60",
        "1.3": "114.40",
        "1.5": "132.00"
    },
    {
        "value": "1500",
        "1.0": "127.00",
        "1.2": "152.40",
        "1.3": "165.10",
        "1.5": "190.50"
    },
    {
        "value": "2000",
        "1.0": "166.00",
        "1.2": "199.20",
        "1.3": "215.80",
        "1.5": "249.00"
    },
    {
        "value": "3000",
        "1.0": "222.00",
        "1.2": "266.40",
        "1.3": "288.60",
        "1.5": "333.00"
    },
    {
        "value": "4000",
        "1.0": "278.00",
        "1.2": "333.60",
        "1.3": "361.40",
        "1.5": "417.00"
    },
    {
        "value": "5000",
        "1.0": "334,00",
        "1.2": "400.80",
        "1.3": "434.20",
        "1.5": "501.00"
    },
    {
        "value": "6000",
        "1.0": "390.00",
        "1.2": "468.00",
        "1.3": "507.00",
        "1.5": "585.00"
    },
    {
        "value": "7000",
        "1.0": "446.00",
        "1.2": "535.20",
        "1.3": "579.80",
        "1.5": "669.00"
    },
    {
        "value": "8000",
        "1.0": "502.00",
        "1.2": "602.40",
        "1.3": "652.60",
        "1.5": "753.00"
    },
    {
        "value": "9000",
        "1.0": "558.00",
        "1.2": "669.60",
        "1.3": "725.40",
        "1.5": "837.00"
    },
    {
        "value": "10000",
        "1.0": "614.00",
        "1.2": "736.80",
        "1.3": "798.20",
        "1.5": "921.00"
    },
    {
        "value": "13000",
        "1.0": "666.00",
        "1.2": "799.20",
        "1.3": "865.80",
        "1.5": "999.00"
    },
    {
        "value": "16000",
        "1.0": "718,00",
        "1.2": "861.60",
        "1.3": "933.40",
        "1.5": "1077.00"
    },
    {
        "value": "19000",
        "1.0": "770.00",
        "1.2": "924.00",
        "1.3": "1001.00",
        "1.5": "1155.00"
    },
    {
        "value": "22000",
        "1.0": "822.00",
        "1.2": "986.40",
        "1.3": "1068.60",
        "1.5": "1233.00"
    },
    {
        "value": "25000",
        "1.0": "874.00",
        "1.2": "1048.80",
        "1.3": "1136.20",
        "1.5": "1311.00"
    },
    {
        "value": "30000",
        "1.0": "955.00",
        "1.2": "1146.00",
        "1.3": "1241.50",
        "1.5": "1432.50"
    },
    {
        "value": "35000",
        "1.0": "1036.00",
        "1.2": "1243.20",
        "1.3": "1346.80",
        "1.5": "1554.00"
    },
    {
        "value": "40000",
        "1.0": "1117.00",
        "1.2": "1340.40",
        "1.3": "1452.10",
        "1.5": "1675.50"
    },
    {
        "value": "45000",
        "1.0": "1198.00",
        "1.2": "1437.60",
        "1.3": "1557.40",
        "1.5": "1797.00"
    },
    {
        "value": "50000",
        "1.0": "1279.00",
        "1.2": "1534.80",
        "1.3": "1662.70",
        "1.5": "1918.50"
    },
    {
        "value": "65000",
        "1.0": "1373.00",
        "1.2": "1647.60",
        "1.3": "1784.90",
        "1.5": "2059.50"
    },
    {
        "value": "80000",
        "1.0": "1467.00",
        "1.2": "1760.40",
        "1.3": "1907.10",
        "1.5": "2200.50"
    },
    {
        "value": "95000",
        "1.0": "1561.00",
        "1.2": "1873.20",
        "1.3": "2029.30",
        "1.5": "2341.50"
    },
    {
        "value": "110000",
        "1.0": "1655.00",
        "1.2": "1986.00",
        "1.3": "2151.50",
        "1.5": "2482.50"
    },
    {
        "value": "125000",
        "1.0": "1749.00",
        "1.2": "2098.80",
        "1.3": "2273.70",
        "1.5": "2623.50"
    },
    {
        "value": "140000",
        "1.0": "1843.00",
        "1.2": "2211.60",
        "1.3": "2395.90",
        "1.5": "2764.50"
    },
    {
        "value": "155000",
        "1.0": "1937.00",
        "1.2": "2324.40",
        "1.3": "2518.10",
        "1.5": "2905.50"
    },
    {
        "value": "170000",
        "1.0": "2031.00",
        "1.2": "2437.20",
        "1.3": "2640.30",
        "1.5": "3046.50"
    },
    {
        "value": "185000",
        "1.0": "2125.00",
        "1.2": "2550.00",
        "1.3": "2762.50",
        "1.5": "3.187,50"
    },
    {
        "value": "200000",
        "1.0": "2219.00",
        "1.2": "2662.80",
        "1.3": "2884.70",
        "1.5": "3328.50"
    },
    {
        "value": "230000",
        "1.0": "2351.00",
        "1.2": "2821.20",
        "1.3": "3056.30",
        "1.5": "3526.50"
    },
    {
        "value": "260000",
        "1.0": "2483.00",
        "1.2": "2979.60",
        "1.3": "3227.90",
        "1.5": "3724.50"
    },
    {
        "value": "290000",
        "1.0": "2615.00",
        "1.2": "3138.00",
        "1.3": "3399.50",
        "1.5": "3922.50"
    },
    {
        "value": "320000",
        "1.0": "2747.00",
        "1.2": "3296.40",
        "1.3": "3571.10",
        "1.5": "4120.50"
    },
    {
        "value": "350000",
        "1.0": "2879.00",
        "1.2": "3454.80",
        "1.3": "3742.70",
        "1.5": "4318.50"
    },
    {
        "value": "380000",
        "1.0": "3011.00",
        "1.2": "3613.20",
        "1.3": "3914.30",
        "1.5": "4516.50"
    },
    {
        "value": "410000",
        "1.0": "3143.00",
        "1.2": "3771.60",
        "1.3": "4085.90",
        "1.5": "4714.50"
    },
    {
        "value": "440000",
        "1.0": "3275.00",
        "1.2": "3930.00",
        "1.3": "4257.50",
        "1.5": "4912.50"
    },
    {
        "value": "470000",
        "1.0": "3407.00",
        "1.2": "4088.40",
        "1.3": "4429.10",
        "1.5": "5110.50"
    },
    {
        "value": "500000",
        "1.0": "3539.00",
        "1.2": "4246.80",
        "1.3": "4600.70",
        "1.5": "5308.50"
    },
    {
        "value": "550000",
        "1.0": "3704.00",
        "1.2": "4444.80",
        "1.3": "4815.20",
        "1.5": "5556.00"
    },
    {
        "value": "600000",
        "1.0": "3869.00",
        "1.2": "4642.80",
        "1.3": "5029.70",
        "1.5": "5803.50"
    },
    {
        "value": "650000",
        "1.0": "4034.00",
        "1.2": "4840.80",
        "1.3": "5244.20",
        "1.5": "6051.00"
    },
    {
        "value": "700000",
        "1.0": "4199.00",
        "1.2": "5038.80",
        "1.3": "5458.70",
        "1.5": "6298.50"
    },
    {
        "value": "750000",
        "1.0": "4364.00",
        "1.2": "5236.80",
        "1.3": "5673.20",
        "1.5": "6546.00"
    },
    {
        "value": "800000",
        "1.0": "4529.00",
        "1.2": "5434.80",
        "1.3": "5887.70",
        "1.5": "6793.50"
    },
    {
        "value": "850000",
        "1.0": "4694.00",
        "1.2": "5632.80",
        "1.3": "6102.20",
        "1.5": "7041.00"
    },
    {
        "value": "900000",
        "1.0": "4859.00",
        "1.2": "5830.80",
        "1.3": "6316.70",
        "1.5": "7288.50"
    },
    {
        "value": "950000",
        "1.0": "5024.00",
        "1.2": "6028.80",
        "1.3": "6531.20",
        "1.5": "7536.00"
    },
    {
        "value": "1000000",
        "1.0": "5189.00",
        "1.2": "6226.80",
        "1.3": "6745.70",
        "1.5": "7783.50"
    },
    {
        "value": "1050000",
        "1.0": "5354.00",
        "1.2": "6424.80",
        "1.3": "6960.20",
        "1.5": "8031.00"
    },
    {
        "value": "1100000",
        "1.0": "5519.00",
        "1.2": "6622.80",
        "1.3": "7174.70",
        "1.5": "8278.50"
    },
    {
        "value": "1150000",
        "1.0": "5684.00",
        "1.2": "6820.80",
        "1.3": "7389.20",
        "1.5": "8526.00"
    },
    {
        "value": "1200000",
        "1.0": "5849.00",
        "1.2": "7018.80",
        "1.3": "7603.70",
        "1.5": "8773.50"
    },
    {
        "value": "1250000",
        "1.0": "6014.00",
        "1.2": "7216.80",
        "1.3": "7818.20",
        "1.5": "9021.00"
    },
    {
        "value": "1300000",
        "1.0": "6179.00",
        "1.2": "7414.80",
        "1.3": "8032.70",
        "1.5": "9268.50"
    },
    {
        "value": "1350000",
        "1.0": "6344.00",
        "1.2": "7612.80",
        "1.3": "8247.20",
        "1.5": "9516.00"
    },
    {
        "value": "1400000",
        "1.0": "6509.00",
        "1.2": "7810.80",
        "1.3": "8461.70",
        "1.5": "9763.50"
    },
    {
        "value": "1450000",
        "1.0": "6674.00",
        "1.2": "8008.80",
        "1.3": "8676.20",
        "1.5": "10011.00"
    },
    {
        "value": "1500000",
        "1.0": "6839.00",
        "1.2": "8206.80",
        "1.3": "8890.70",
        "1.5": "10258.50"
    },
    {
        "value": "1550000",
        "1.0": "7004.00",
        "1.2": "8404.80",
        "1.3": "9105.20",
        "1.5": "10506.00"
    },
    {
        "value": "1600000",
        "1.0": "7169.00",
        "1.2": "8602.80",
        "1.3": "9319.70",
        "1.5": "10753.50"
    },
    {
        "value": "1650000",
        "1.0": "7334.00",
        "1.2": "8800.80",
        "1.3": "9534.20",
        "1.5": "11001.00"
    },
    {
        "value": "1700000",
        "1.0": "7499.00",
        "1.2": "8998.80",
        "1.3": "9748.70",
        "1.5": "11248.50"
    },
    {
        "value": "1750000",
        "1.0": "7664.00",
        "1.2": "9196.80",
        "1.3": "9963.20",
        "1.5": "11496.00"
    },
    {
        "value": "1800000",
        "1.0": "7829.00",
        "1.2": "9394.80",
        "1.3": "10177.70",
        "1.5": "11743.50"
    },
    {
        "value": "1850000",
        "1.0": "7994.00",
        "1.2": "9592.80",
        "1.3": "10392.20",
        "1.5": "11991.00"
    },
    {
        "value": "1900000",
        "1.0": "8159.00",
        "1.2": "9790.80",
        "1.3": "10606.70",
        "1.5": "12238.50"
    },
    {
        "value": "1950000",
        "1.0": "8324.00",
        "1.2": "9988.80",
        "1.3": "10821.20",
        "1.5": "12486.00"
    },
    {
        "value": "2000000",
        "1.0": "8489.00",
        "1.2": "10186.80",
        "1.3": "11035.70",
        "1.5": "12733.50"
    }
]

var gerichtskostenTabelle = [
    {
        "value": 500,
        "cost": 38.00
    },
    {
        "value": 1000,
        "cost": 58.00
    },
    {
        "value": 1500,
        "cost": 78.00
    },
    {
        "value": 2000,
        "cost": 98.00
    },
    {
        "value": 3000,
        "cost": 119.00
    },
    {
        "value": 4000,
        "cost": 140.00
    },
    {
        "value": 5000,
        "cost": 161.00
    },
    {
        "value": 6000,
        "cost": 182.00
    },
    {
        "value": 7000,
        "cost": 203.00
    },
    {
        "value": 8000,
        "cost": 224.00
    },
    {
        "value": 9000,
        "cost": 245.00
    },
    {
        "value": 10000,
        "cost": 266.00
    },
    {
        "value": 13000,
        "cost": 295.00
    },
    {
        "value": 16000,
        "cost": 324.00
    },
    {
        "value": 19000,
        "cost": 324.00
    },
    {
        "value": 22000,
        "cost": 324.00
    },
    {
        "value": 25000,
        "cost": 411.00
    },
    {
        "value": 30000,
        "cost": 411.00
    },
    {
        "value": 35000,
        "cost": 487.00
    },
    {
        "value": 40000,
        "cost": 525.00
    },
    {
        "value": 45000,
        "cost": 563.00
    }
];

export default function CostCalculator() {
    const mwst = 0.19;
    const [anliegen, setAnliegen] = useState("Kündigung");
    const [showResult, setShowResult] = useState(false);
    const [fehlbetrag, setFehlbetrag] = useState("");
    const [bruttoGehalt, setBruttoGehalt] = useState("");
    const [anzahlAbmahnungen, setAnzahlAbmahnungen] = useState("Eine");
    const [typeOfKlärung, setTypeOfKlärung] = useState("außergerichtlich");


    const [nettoPerson1, setNettoPerson1] = useState(""); //netto person 1
    const [nettoPerson2, setNettoPerson2] = useState(""); //netto person 2
    const [numberOfAdvocacies, setNumberOfAdvocacies] = useState(0); //anzahl anwaltschaften
    const [hasPensionAdjustment, setHasPensionAdjustment] = useState(true); //versorgungsausgleich
    const [jointLawyer, setJointLawyer] = useState(true);

    const _hasPensionAdjustmentHandler = (e) => {
        if (hasPensionAdjustment) {
            setHasPensionAdjustment(false, setNumberOfAdvocacies(0));
        } else {
            setHasPensionAdjustment(true, setNumberOfAdvocacies(0));
        }
    }

    const _nettoHandler = (value, handler) => {
        var newValue = value;
        if (newValue > 10000) {
            newValue = 10000;
        }

        handler(newValue);
    }

    const _formatNumbers = number => {
        number = parseFloat(number);
        number = number.toFixed(2) + '';
        var x = number.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? ',' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }
        return x1 + x2;
    }

    const _handleFocus = (event) => event.target.select();

    var verfahrenswert = 0;
    var verfahrensgebühr = 0;
    var terminsgebühr = 0;
    var auslagepauschale = 20;
    var eingangsgebühr = 0;
    var umsatzsteuer = 0;
    var gerichtskosten = 0;
    var gesamtkosten = 0;
    var anwaltskosten = 0;

    switch( anliegen ) {
        case "Kündigung":
            verfahrenswert = (bruttoGehalt * 3)
            break;
        case "Zeugnis":
            verfahrenswert = bruttoGehalt;
            break;
        case "Gehalt":
            verfahrenswert = fehlbetrag;
            break;
        case "Abmahnung":
            if( anzahlAbmahnungen === "Eine" ) {
                verfahrenswert = bruttoGehalt;
            }else {
                verfahrenswert = (3 * bruttoGehalt);
            }
            break;
    }

    var tableRow = table.filter( row => parseInt(row.value) >= verfahrenswert );
    if( tableRow.length > 0 ) {
        verfahrensgebühr = tableRow[0]['1.3'];
        terminsgebühr = tableRow[0]['1.2'];
        if( typeOfKlärung === "außergerichtlich" ) {
            eingangsgebühr = tableRow[0]['1.5'];
        }else {
            eingangsgebühr = tableRow[0]['1.0'];
        }
    }

    var tableRow2 = gerichtskostenTabelle.filter( row => parseInt(row.value) >= verfahrenswert );
    if( tableRow2.length > 0 ) { 
        gerichtskosten = parseFloat( tableRow2[0].cost);
    }

    umsatzsteuer = parseFloat(verfahrensgebühr) + parseFloat(terminsgebühr) + parseFloat(auslagepauschale) + parseFloat(eingangsgebühr);
    umsatzsteuer = (parseFloat(verfahrensgebühr) + parseFloat(terminsgebühr) + parseFloat(auslagepauschale) + parseFloat(eingangsgebühr)) * mwst;
    anwaltskosten = parseFloat(umsatzsteuer) + parseFloat(auslagepauschale) + parseFloat(terminsgebühr) + parseFloat(verfahrensgebühr) + parseFloat(eingangsgebühr);
    gesamtkosten = parseFloat(anwaltskosten) + parseFloat(gerichtskosten);
    
    return (
        <div className="vhr-divorce-calculator">
            {!showResult ?
                <div>
                    <div>
                        <select value={anliegen} onChange={(e) => setAnliegen(e.target.value)}>
                            <option value="Kündigung">Kündigung</option>                            
                            <option value="Abmahnung">Abmahnung</option>                            
                            <option value="Zeugnis">Zeugnis</option>                            
                            <option value="Gehalt">Gehalt</option>                            
                        </select>
                    </div>
                    {anliegen == "Gehalt" ? 
                        <div>
                            <label>
                                <input
                                    type="number"
                                    name=""
                                    value={fehlbetrag}
                                    placeholder="Fehlbetrag in €"
                                    max="10000"
                                    onChange={(e) => _nettoHandler( e.target.value, setFehlbetrag)}
                                    onFocus={_handleFocus}
                                />
                            </label>
                        </div>
                    : 
                        <div>
                            <label>
                                <input
                                    type="number"
                                    name=""
                                    value={bruttoGehalt}
                                    placeholder="Mein Brutogehalt in €"
                                    max="10000"
                                    onChange={(e) => _nettoHandler( e.target.value, setBruttoGehalt)}
                                    onFocus={_handleFocus}
                                />
                            </label>
                        </div>
                    }
                    {anliegen === "Abmahnung" ? 
                    <div>
                        <p>Wie viele Abmahnungen hatten Sie bekommen?</p>
                        <div className="grid-2">
                            <div className="checkbox">
                                <input
                                    type="radio"
                                    value="Eine"
                                    name="abmahnungen"
                                    onChange={e => setAnzahlAbmahnungen(e.target.value)}
                                    id="anzahlAbmahnung-ein"
                                    checked={anzahlAbmahnungen === "Eine"}
                                />
                                <label htmlFor="anzahlAbmahnung-ein" className="checkbox__label">
                                    <span className="checkbox__icon"></span>
                                    Eine
                                </label>
                            </div>
                            <div className="checkbox">
                                <input
                                    type="radio"
                                    value="Mehrere"
                                    name="abmahnungen"
                                    onChange={e => setAnzahlAbmahnungen(e.target.value)}
                                    id="anzahlAbmahnung-Mehrere"
                                    checked={anzahlAbmahnungen === "Mehrere"}
                                />
                                <label htmlFor="anzahlAbmahnung-Mehrere" className="checkbox__label">
                                    <span className="checkbox__icon"></span>
                                    Mehrere
                                </label>
                            </div>
                        </div>
                    </div>

                    : ""}
                    <div>
                        <p>Wie wollen Sie Ihr Anliegen klären?</p>
                        <div className="grid-2">
                            <div className="checkbox">
                                <input
                                    type="radio"
                                    value="außergerichtlich"
                                    name="klärung"
                                    onChange={e => setTypeOfKlärung(e.target.value)}
                                    id="außergerichtlich"
                                    checked={typeOfKlärung === "außergerichtlich"}
                                />
                                <label htmlFor="außergerichtlich" className="checkbox__label">
                                    <span className="checkbox__icon"></span>
                                    außergerichtlich
                                </label>
                            </div>
                            <div className="checkbox">
                                <input
                                    type="radio"
                                    value="gerichtlich"
                                    name="klärung"
                                    onChange={e => setTypeOfKlärung(e.target.value)}
                                    id="gerichtlich"
                                    checked={typeOfKlärung === "gerichtlich"}
                                />
                                <label htmlFor="gerichtlich" className="checkbox__label">
                                    <span className="checkbox__icon"></span>
                                    gerichtlich
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="vrh-btn" onClick={() => setShowResult(true)}>Berechnen</button>
                    </div>
                </div>
                :
                <div>
                    <dl>
                        <dt>Gesamtkosten <span className="vrh-tooltip stepTwo"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg><span className="vrh-tooltip__content">Es sind nur die Kosten des Verfahrens über den Bestand des Arbeitsvertrages gemeint.</span></span></dt>
                        <dd>{_formatNumbers(gesamtkosten)}&nbsp;€</dd>
                        <dt>Verfahrenswert</dt>
                        <dd>{_formatNumbers(verfahrenswert)}&nbsp;€</dd>
                        <dt>Anwaltskosten</dt>
                        <dd>{_formatNumbers(anwaltskosten)}&nbsp;€</dd>
                    </dl>
                    <ul>
                        <li>{jointLawyer ? _formatNumbers(verfahrensgebühr) : _formatNumbers(verfahrensgebühr)}&nbsp;€ {typeOfKlärung === "außergerichtlich" ? "Geschäftsgebühr" : "Verfahrensgebühr"}</li>
                        <li>{jointLawyer ? _formatNumbers(terminsgebühr) : _formatNumbers(terminsgebühr)}&nbsp;€ Terminsgebühr</li>
                        <li>{jointLawyer ? _formatNumbers(auslagepauschale) : _formatNumbers(auslagepauschale)}&nbsp;€ Auslagepauschale</li>
                        <li>{jointLawyer ? _formatNumbers(eingangsgebühr) : _formatNumbers(eingangsgebühr)}&nbsp;€ Einigungsgebühr</li>
                        {<li>{jointLawyer ? _formatNumbers( umsatzsteuer ) : _formatNumbers( umsatzsteuer) }&nbsp;€ 19% Umsatzsteuer</li>}
                    </ul>
                    <dl>
                        <dt>Gerichtskosten</dt>
                        <dd>{_formatNumbers(0.00)}&nbsp;€{typeOfKlärung === "außergerichtlich" ? "" : <sup>*</sup> }</dd>
                    </dl>
                    {typeOfKlärung === "außergerichtlich" ? "" : <p style={{textAlign: "left"}}><small><sup>*</sup>bei Vergleich (im Falle eines Urteiles {_formatNumbers((gerichtskosten * 2))}EUR)</small></p> }
                    <div style={{ display: "block", width: "100%", height: "5px" }}>
                        <button style={{ backgroundColor: "transparent", border: "none", float: "left", padding: "0" }} onClick={() => setShowResult(false)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Zurück zur Berechnung</button>
                    </div>
                </div>

            }

        </div>
    )
}