import React from "react";

export default function Imprint(props) {
    props._bgColorHandler('VIOLET');

    return (
        <section class="vrh-imprint">
            <h1>Impressum</h1>
            <p>
                <b>Kontakt</b>
        GERLING | SCHULZ | LÜDERS<br />
        Rechtsanwälte – Fachanwälte<br />
        Frankfurter Straße 85<br />
        53773 Hennef<br /><br />

        Tel.: 02242 / 933 10 70<br />
        Fax.: 02242 / 933 10 88<br /><br />

        E-Mail: info@dein-scheidungshelfer.de<br /><br />

                <b>Rechtsform</b>
        GbR<br /><br />

                <b>Vertretungsberechtigte</b>
        Rechtsanwälte Rainer Lüders, Jörg Bender, Dr. Tanja Schulz-Firley, Oliver Schunck<br /><br />

                <b>Zuständige Aufsichtsbehörde und Kammer</b>
        Rechtsanwaltskammer Köln<br />
        Riehler Straße 30<br />
        50668 Köln<br /><br />

                <b>Berufsbezeichnung und berufsrechtliche Regelungen</b>
        Die gesetzliche Berufsbezeichnung lautet Rechtsanwalt. Die Berufsbezeichnung wurde in der Bundesrepublik Deutschland verliehen.<br /><br />

        Die Rechtsanwälte unterliegen den folgenden berufsrechtlichen Regelungen:<br /><br />
        • Der Bundesrechtsanwaltsordnung (BRAO)<br />
        • der Berufsordnung für Rechtsanwälte (BORA), der Fachanwaltsordnung (FAO)<br />
        • dem Gesetz über die Vergütung der Rechtsanwältinnen und Rechtsanwälte (Rechtsanwaltsvergütungsgesetz – RVG)<br />
        • den Berufsregeln der Rechtsanwälte der Europäischen Union (CCBE) sowie dem<br />
        • Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland vom 9. März 2000 (EuRAG)<br /><br />

        Diese und weitere berufsrechtlichen Regelungen finden Sie auf der Internetseite der Bundesrechtsanwaltskammer (http://www.brak.de/) unter „Berufsrecht“.<br /><br />

                <b>Umsatzsteuer-ID</b>
        DE 123128164<br /><br />

                <b>Berufshaftpflichtversicherung</b>
        R+V Vermögensschaden-Haftpflichtversicherung<br />
        R+V Versicherung AG<br />
        Raiffeisenplatz 1<br />
        65189 Wiesbaden<br /><br />

                <b>Verantwortlicher i.S.d. § 55 Abs. 2 RStV für die journalistisch-redaktionellen Inhalte</b>
        Rechtsanwalt Jörg Bender<br /><br />

        Inhaber der Domain „rechtsanwalt-hennef.de“ und verantwortlich nach § 6 MDStV und § 8 TDG ist Rechtsanwalt Jörg Bender.<br /><br />

                <b>Inhaltlich verantwortlich nach § 10 MDStV</b>
        Rechtsanwalt Jörg Bender.<br /><br />

                <b>Hinweis gem. ODR-Verordnung</b>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit, die Sie unter https://.ec.europa.eu/consumers/odr finden.<br /><br />

                <b>Hinweis gem. § 36 VSBG</b>
        Wir werden nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilnehmen und sind hierzu auch nicht verpflichtet.
      </p>
        </section>
    );
}