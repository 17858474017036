import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function WarningInformationsFirst(props) {
    const [warningPreTalk, setWarningPreTalk] = useState(props.formular.warningPreTalk || "no");
    const [firstWarningLetter, setFirstWarningLetter] = useState(props.formular.firstWarningLetter || "no");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            warningPreTalk: warningPreTalk,
            firstWarningLetter: firstWarningLetter
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        console.log( firstWarningLetter );
        if( firstWarningLetter === "No" ) {
            return ( <Redirect push to={`${process.env.PUBLIC_URL}/besonderheiten/`} /> );
        } else {
            return ( <Redirect push to={`${process.env.PUBLIC_URL}/abmahnungs-informationen/2`} /> );
        }
    } else {
        return(
            <div className="djh-warning-information-first">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Abmahnung</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Hat man mit Ihnen gesprochen bevor Sie die Abmahnung erhalten haben?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="warningPreTalk"
                                onChange={e => setWarningPreTalk(e.target.value)}
                                id="warningPreTalk-Yes"
                                checked={warningPreTalk === "Yes"}
                            />
                            <label htmlFor="warningPreTalk-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="warningPreTalk"
                                onChange={e => setWarningPreTalk(e.target.value)}
                                id="warningPreTalk-No"
                                checked={warningPreTalk === "No"}
                            />
                            <label htmlFor="warningPreTalk-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <div>
                        <p>Ist dies die erste Abmahnung oder haben Sie in der Vergangenheit schon welche erhalten?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="firstWarningLetter"
                                onChange={e => setFirstWarningLetter(e.target.value)}
                                id="firstWarningLetter-Yes"
                                checked={firstWarningLetter === "Yes"}
                            />
                            <label htmlFor="firstWarningLetter-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="firstWarningLetter"
                                onChange={e => setFirstWarningLetter(e.target.value)}
                                id="firstWarningLetter-No"
                                checked={firstWarningLetter === "No"}
                            />
                            <label htmlFor="firstWarningLetter-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function WarningInformationsSecond(props) {
    const [countOfWarningLetter, setCountOfWarningLetter] = useState(props.formular.countOfWarningLetter || "");
    const [warningLetterOlderThenOneYear, setWarningLetterOlderThenOneYear] = useState(props.formular.warningLetterOlderThenOneYear || "no");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            countOfWarningLetter: countOfWarningLetter,
            warningLetterOlderThenOneYear: warningLetterOlderThenOneYear
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/besonderheiten/`} /> );
    } else {
        return(
            <div className="djh-warning-information-second">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/abmahnungs-informationen/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Abmahnung</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Wieviele Abmahnungen haben Sie in der Vergangenheit erhalten?</p>
                        <label>
                            <input
                                type="number"
                                value={countOfWarningLetter}
                                onChange={e => setCountOfWarningLetter(e.target.value)}
                                required
                                placeholder="Anzal der Abmahnungen"
                            />
                        </label>
                    </div>
                    <div>
                        <p>Sind die Abmahnungen älter als ein Jahr?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="warningLetterOlderThenOneYear"
                                onChange={e => setWarningLetterOlderThenOneYear(e.target.value)}
                                id="warningLetterOlderThenOneYear-Yes"
                                checked={warningLetterOlderThenOneYear === "Yes"}
                            />
                            <label htmlFor="warningLetterOlderThenOneYear-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="warningLetterOlderThenOneYear"
                                onChange={e => setWarningLetterOlderThenOneYear(e.target.value)}
                                id="warningLetterOlderThenOneYear-No"
                                checked={warningLetterOlderThenOneYear === "No"}
                            />
                            <label htmlFor="warningLetterOlderThenOneYear-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}


function WarningInformations(props) {
    props._bgColorHandler('abmahnung');
    return(
        <section>
            <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_abmahnung.svg`} alt="" title="" />
            <Route exact path={`${process.env.PUBLIC_URL}/abmahnungs-informationen/`}>
                <WarningInformationsFirst formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/abmahnungs-informationen/2`}>
                <WarningInformationsSecond formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
        </section>
    )
}

export default WarningInformations;