import React, { useState, useCallback } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
var mapStyle = require("./../mapStyle.json");


const position = {
    lat: 50.774610,
    lng: 7.286303
};

const center = {
    lat: 50.774610,
    lng: 7.286303
}

const GoogleMapComponentWithMarker = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={center}
            defaultOptions={{
                disableDefaultUI: true, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: true, // allow scale controle
                scrollwheel: true, // allow scroll wheel
                styles: mapStyle // change default map styles
            }}
        >
            <Marker
                position={position}
            />
        </GoogleMap>
    ))
);


export default function Contact(props) {
    const [emailName, setEmailName] = useState("");
    const [eMail, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [ifMessageSuccess, setIfMessageSuccess] = useState(false);

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    props._bgColorHandler('kontakt');

    const onSubmit = (e) => {
        e.preventDefault();
        var formElement = document.getElementById("contact-form");
        var formData = new FormData(formElement);
        var url = 'contact.php';
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    setIfMessageSuccess(true);
                }
            }
        }
        xhr.open('POST', url, true);
        xhr.send(formData);
    }

    return (
        <><section className="vrh-contact djh-contact">
            <div className="vrh-team__grid">
                <div style={{ height: "100%", width: "100%", minWidth: "500px", minHeight: "500px" }}>
                <GoogleMapComponentWithMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBJ-KHkzFqhFV5GDeTU_iFyecWdlRQS3s0"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
                <div>
                    <h1>Kontakt</h1>
                    <p>Bitte nutzen Sie das folgende Online-Formular, um mit uns in Kontakt zu treten. Ihre Eingaben unterliegen selbstverständlich der anwaltlichen Schweigepflicht. Durch Ihre Anfrage kommt <u>kein Mandatsverhältnis</u> zustande. Sie können uns <b>hier kostenlos kontaktieren</b> und Ihr Anliegen schildern. Wir werden uns schnellstmöglich bei Ihnen telefonisch oder per E-Mail melden und in der Regel eine erste Einschätzung des Sachverhalts sowie eine Abschätzung der voraussichtlichen Kosten zukommen lassen.</p>
                    <form onSubmit={(e) => onSubmit(e)} id="contact-form">
                        <label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={emailName}
                                onChange={(value) => setEmailName(value.target.value)}
                                required />
                        </label>
                        <label>
                            <input
                                type="text"
                                placeholder="E-Mail"
                                name="mail"
                                value={eMail}
                                onChange={(value) => setEmail(value.target.value)}
                                required />
                        </label>
                        <label>
                            <input
                                type="text"
                                name="phone"
                                placeholder="Telefon"
                                value={phone}
                                onChange={(value) => setPhone(value.target.value)}
                            />
                        </label>
                        <textarea
                            placeholder="Nachricht"
                            name="message"
                            value={message}
                            onChange={(value) => setMessage(value.target.value)}
                            required
                        ></textarea>
                        {
                            ifMessageSuccess ? <h3>Wir haben Ihre Anfrage erhalten.</h3> : <button type="submit">
                                Absenden
                                <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="02-Meine-Daten---Seite-1" transform="translate(-747.000000, -882.000000)" fill="#252C32">
                                            <g id="Group-2" transform="translate(665.000000, 871.000000)">
                                                <g id="Group">
                                                    <path d="M95.5612786,13.9001297 L92.7710155,11 L92.0609486,11.7367056 L94.706198,14.4811933 L82,14.4811933 L82,15.5188067 L94.706198,15.5188067 L92.0609486,18.2632944 L92.7710155,19 L95.5612786,16.0998703 C96.1462405,15.4922074 96.1462405,14.5077926 95.5612786,13.9001297 L95.5612786,13.9001297 Z" id="Style"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        }
                    </form>
                </div>
            </div>
        </section>
        </>
    );
}