import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

export default function Faq(props) {
    props._bgColorHandler('faq');
    
    return (
        <section className="vrh-faq djh-faq">
            <h1>FAQs</h1>
            <ul>
                <li>
                    <input type="checkbox" id="faq-1" />
                    <label for="faq-1">
                        Was kostet mich die erste Kontaktaufnahme?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Die erste Kontaktaufnahme ist kostenlos.</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-2" />
                    <label for="faq-2">
                        Was kostet mich die Vertretung durch die Jobhelfer? 
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Das hängt davon ab, worum es geht. Die Kosten werden bei einem Rechtsstreit vom Gericht festgelegt. Auf Grund langjähriger Erfahrung können wir aber schon zu Beginn eine
                        recht genaue Kostenschätzung zur Verfügung stellen. Die Kosten nach dem Rechtsanwaltsvergütungsgesetz sind von Ihrem Gehalt abhängig. Eine Beratung berechnen wir meist mit
                        einer günstigen Pauschale.
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-3" />
                    <label for="faq-3">
                        Wie lange dauert ein Kündigungsstreit? 
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Nach Einreichen der Klage vergibt das Arbeitsgericht sehr kurzfristig einen ersten Gütetermin. Häufig kann eine Klärung der Sache binnen 3-4 Wochen herbeigeführt und das Verfahren abgeschlossen werden. Ansonsten hängt die Dauer eines Gerichtsverfahrens von
                        vielen Faktoren ab und kann sehr unterschiedlich sein, je nach Komplexität der Sache. Wir
                        sind stets um größtmögliche Beschleunigung bemüht. Selbstverständlich profitieren Sie
                        dabei von unserer langjährigen Erfahrung.
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-4" />
                    <label for="faq-4">
                        Gibt es versteckte Kosten?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Kostentransparenz ist für uns besonders wichtig. Wir sprechen mit Ihnen von Beginn an ganz offen über die anfallenden Kosten. Daher haben sie bei uns keine versteckten Kosten zu befürchten.</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-5" />
                    <label for="faq-5">
                        Wie lange dauert die Bearbeitung?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Sie hören werktags (Montag bis Freitag) innerhalb von 24 Stunden nach Auftragseingang von uns. Sollten Angaben fehlen oder unvollständig sein, so klären wir das ganz unkompliziert mit Ihnen persönlich. Sobald wir alle erforderlichen Unterlagen haben, macht sich unser Team aus Scheidungshelfern sofort an die Arbeit. In der Regel ist Ihr Scheidungsantrag innerhalb von 24 h fertig und kann von Ihnen freigegeben werden.</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-6" />
                    <label for="faq-6">
                        Ich bin beruflich und privat stark eingebunden, muss ich viele Termine wahrnehmen?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Nein, gar nicht. Wir bieten Ihnen die Bearbeitung und Abwicklung so bequem wie möglich an. Sie haben keine Zeit für einen Termin in unserer Kanzlei, kein Problem! Wir können alles online, per E-Mail und telefonisch klären. Gerne auch per Videotelefonie. So behalten Sie die Kontrolle über Ihre Zeit. Dabei reduzieren wir Ihren Aufwand auf ein absolutes Minimum. Selbstverständlich müssen Sie den gerichtlichen Scheidungstermin mit uns gemeinsam wahrnehmen, dieser Termin dauert aber aufgrund unserer Vorarbeit nur wenige Minuten (in der Regel 10 Minuten).</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-7" />
                    <label for="faq-7">
                        Wie können Sie uns kontaktieren?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Außerhalb des Scheidungshelfers erreichen Sie uns gerne telefonisch (02242/9331070), per E-Mail (info@dein-scheidungshelfer.de), per Fax (02242/9331088) oder in einem persönlichen Gespräch vor Ort in unseren Kanzleiräumen in der Frankfurter Straße 85 in 53773 Hennef.</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-8" />
                    <label for="faq-8">
                        Bieten sie auch die Möglichkeit der Beratung per Videotelefonie an?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Selbstverständlich! Wir verfügen über die modernsten technischen Geräte und können so mit Ihnen eine Videotelefonie auf den gängigsten Plattformen durchführen.</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-9" />
                    <label for="faq-9">
                        Welche Vorteile bietet mir der Job-Helfer?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Sie haben den Vorteil, dass die sonst sehr zeitaufwändige Auseinandersetzung auf einen
                        minimalen Aufwand für Sie reduziert wird. Sie müssen lediglich die Online-Fragen und etwaige Rückfragen beantworten. Das dauert nur wenige Minuten. Um den Rest kümmert
                        sich unser erfahrenes Expertenteam, das auf eine Vielzahl von erfolgreich bearbeiteten
                        arbeitsrechtlichen Angelegenheiten verweisen kann. Als Ihre regionale Anwaltskanzlei sind
                        wir für Sie aber nicht nur online, sondern natürlich auch vor Ort persönlich erreichbar.
                        Wir kennen die Rechtsprechung der Gerichte vor Ort. Über alle Schritte und den jeweiligen Sachstand der Abwicklung werden Sie von uns regelmäßig informiert.
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-10" />
                    <label for="faq-10">
                        Muss ich mich darüber hinaus mit dem Arbeitsgericht auseinandersetzen? 
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Nein! Auch hier nehmen wir Ihnen die Arbeit und den Stress ab. Wir erledigen den Schriftverkehr mit dem Gericht. Natürlich treffen Sie dabei die Entscheidungen. Unser Vorgehen
                        ist immer mit Ihnen abgesprochen. Selbstverständlich zeigen wir Ihnen dabei sämtliche
                        Möglichkeiten auf. 
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-11" />
                    <label for="faq-11">
                        Wer sind die Job-Helfer?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Wir sind ein Anwaltsteam mit langjähriger Erfahrung, mit der Spezialisierung des <b>Fachanwalts</b> im Arbeits- und Sozialrecht und mit regionaler Anbindung. 
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-12" />
                    <label for="faq-12">
                        Was ist der Unterschied der Job-Helfer zu anderen Onlineanbietern? 
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Wir sind als Anwaltskanzlei bereits seit über 60 Jahren in der Region für Sie da. Daher haben Sie bei uns jederzeit die Möglichkeit, einen Ansprechpartner persönlich zu erreichen.
                        Als regionaler Ansprechpartner vor Ort sind wir mit den Gerichten und Anwaltskollegen
                        der Region im Austausch. Gleichzeitig bieten wir Ihnen modernsten und höchsten digitalen
                        Standard zur schnellen Online-Beratung an. Wir sind regional und gleichzeitig online mit
                        minimalem Zeitaufwand für Sie da!
                    </div>
                </li>
                <li>
                    <input type="checkbox" id="faq-13" />
                    <label for="faq-13">
                        Was ist der Unterschied der Scheidungshelfer zu anderen Onlineanbietern?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Wir sind als Anwaltskanzlei bereits seit über 60 Jahren in der Region für Sie da. Daher haben Sie bei uns jederzeit die Möglichkeit, einen Ansprechpartner persönlich zu erreichen. Als regionaler Ansprechpartner vor Ort sind wir mit den Gerichten und Anwaltskollegen der Region im Austausch. Gleichzeitig bieten wir Ihnen modernsten und höchsten digitalen Standard zur schnellen Online-Scheidung an. Wir sind regional und gleichzeitig online mit minimalem Zeitaufwand für Sie da!</div>
                </li>
                <li>
                    <input type="checkbox" id="faq-14" />
                    <label for="faq-14">
                        Was passiert mit meinen online eingegebenen Daten?
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>Ihre Daten sind bei uns sicher. Wir unterliegen der anwaltlichen Schweigepflicht und dem geltenden Datenschutzrecht. Näheres finden Sie unter <Link to={`${process.env.PUBLIC_URL}/datenschutz`} title="Datenschutz" target="_blank">Datenschutz</Link></div>
                </li>
                <li>
                    <input type="checkbox" id="faq-15" />
                    <label for="faq-15">
                        Wer trägt die Kosten für die Vertretung durch die Job-Helfer? 
                        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="FAQ" transform="translate(-669.000000, -447.000000)" stroke="#F3F4F5">
                                    <g id="Group-Copy-2" transform="translate(670.000000, 448.000000)">
                                        <path d="M12.5,2.96651592e-13 C5.59644064,2.96651592e-13 -5.15143483e-14,5.59644064 -5.15143483e-14,12.5 C-5.15143483e-14,19.4035594 5.59644064,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C25,9.18479391 23.6830396,6.00536998 21.3388348,3.66116525 C18.9946301,1.31696051 15.8152062,2.96651592e-13 12.5,2.96651592e-13 Z" id="Path"></path>
                                        <polyline id="Path-6" stroke-linecap="round" stroke-linejoin="round" points="6 10 12.5 16 19 10"></polyline>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </label>
                    <div>
                        Prinzipiell berechnen wir Ihnen als Auftraggeber die Kosten unserer Tätigkeit. Wenn Sie
                        über eine Rechtschutzversicherung verfügen, trägt in arbeitsrechtlichen Angelegenheiten
                        in der Regeln die Versicherung im Umfang des Versicherungsschutzes die Kosten. Ansonsten gibt es noch die Möglichkeit der Prozesskostenhilfe über die wir Sie gerne beraten.
                        Nicht möglich ist leider in der Regel eine Erstattung der Kosten durch die Gegenseite, da
                        das nach § 12a ArbGG in der Regel nicht vorgesehen ist. 
                    </div>
                </li>
            </ul>
        </section>
    )
}