import React, { useState, useEffect } from 'react';
import {
    Route,
    Link,
    Redirect
} from "react-router-dom";

function SalaryInformationFirst(props) {
    const [missingMoney, setMissingMoney] = useState(props.formular.missingMoney || "");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            missingMoney: missingMoney
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/gehalts-informationen/2`} /> );
    } else {
        return(
            <div className="djh-salary-information-first">
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Gehalt</h1>
                <p>Haben Sie weniger Geld bekommen als sonst?</p>
                
                <form onSubmit={_handleSubmit}>
                    <label>
                        Wieviel fehlt nach Ihrer Rechnung ungefähr?
                        <input
                            type="text"
                            value={missingMoney}
                            onChange={e => setMissingMoney(e.target.value)}
                            placeholder="Euro"
                        />
                    </label>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

function SalaryInformationSecond(props) {
    const [hasPayroll, setHasPayroll] = useState(props.formular.hasPayroll || "");
    const [monthOfError, setMonthOfError] = useState(props.formular.monthOfError || "");
    const [isFormValidated, setIsFormValidated] = useState(false);

    const _handleSubmit = (evt) => {
        evt.preventDefault();
        let formular = {
            hasPayroll: hasPayroll,
            monthOfError: monthOfError
        }
        props._formularHandler( formular );
        setIsFormValidated( true );
    }

    if( isFormValidated ) {
        return ( <Redirect push to={`${process.env.PUBLIC_URL}/besonderheiten/`} /> );
    } else {
        return(
            <div className="djh-salary-information-second"> 
                <Link className="vhr-back-btn" to={`${process.env.PUBLIC_URL}/gehalts-informationen/`}>
                    <svg width="40px" height="23px" viewBox="0 0 40 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="02-Meine-Daten" transform="translate(-100.000000, -501.000000)" fill="#252C32">
                                <g id="Group" transform="translate(100.000000, 501.000000)">
                                    <path d="M38.7465102,8.28608486 L30.77433,1.59872116e-14 L28.7455673,2.10487308 L36.3034227,9.94626644 L0,9.94626644 L0,12.9108764 L36.3034227,12.9108764 L28.7455673,20.7522698 L30.77433,22.8571429 L38.7465102,14.571058 C40.4178299,12.8348784 40.4178299,10.0222645 38.7465102,8.28608486 L38.7465102,8.28608486 Z" id="Style-Copy" transform="translate(20.000000, 11.428571) scale(-1, 1) translate(-20.000000, -11.428571) "></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Zurück</span>
                </Link>
                <h1><small>Schritt 2 von 5</small>Gehalt</h1>
                <form onSubmit={_handleSubmit}>
                    <div>
                        <p>Haben Sie Sie schon eine Gehaltsabrechnung?</p>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="Yes"
                                name="hasPayroll"
                                onChange={e => setHasPayroll(e.target.value)}
                                id="hasPayroll-Yes"
                                checked={hasPayroll === "Yes"}
                            />
                            <label htmlFor="hasPayroll-Yes" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Ja
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="radio"
                                value="No"
                                name="hasPayroll"
                                onChange={e => setHasPayroll(e.target.value)}
                                id="hasPayroll-No"
                                checked={hasPayroll === "No"}
                            />
                            <label htmlFor="hasPayroll-No" className="checkbox__label">
                                <span className="checkbox__icon"></span>
                                Nein
                            </label>
                        </div>
                    </div>
                    <br/>
                    <label>
                        In welchen Monat ist der Fehler passiert?
                        <select value={monthOfError} onChange={(e) => setMonthOfError(e.target.value)}>
                            <option value="" disabled>Monat auswählen</option>
                            <option value="Januar">Januar</option>
                            <option value="Februar">Februar</option>
                            <option value="März">März</option>
                            <option value="April">April</option>
                            <option value="Mai">Mai</option>
                            <option value="Juni">Juni</option>
                            <option value="Juli">Juli</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="Oktober">Oktober</option>
                            <option value="November">November</option>
                            <option value="Dezember">Dezember</option>
                        </select>
                    </label>
                    <button type="submit">
                        Weiter
                    </button>
                </form>
            </div>
        );
    }
}

export default function SalaryInformation(props) {
    props._bgColorHandler('gehalt');

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return(
        <section>
            <img src={`${process.env.PUBLIC_URL}/assets/images/jobhelfer_gehalt.svg`} alt="" title="" />
            <Route exact path={`${process.env.PUBLIC_URL}/gehalts-informationen/`}>
                <SalaryInformationFirst formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/gehalts-informationen/2`}>
                <SalaryInformationSecond formular={props.formular} _formularHandler={props._formularHandler} />
            </Route>
        </section>
    )
}