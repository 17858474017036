const formular = JSON.parse(JSON.stringify({
    "id": "",
    "concernType": "",
    "firstname": "",
    "lastname": "",
    "legalProtectionInsurance": "",
    "pregnant": "",
    "handicapped": "",
    "memberOfWorksCouncil": "",
    "dataPrivacyOfficer": "",
    "warningPreTalk": "",
    "firstWarningLetter": "",
    "countOfWarningLetter": "",
    "missingMoney": "",
    "hasPayroll": "",
    "monthOfError": "",
    "otherIssue": "",
    "additionalNote": "",
    "warningLetterOlderThenOneYear": "",
    "certificate": {
        "certificateReceived": "",
        "certificateSpellingError": "",
        "certificateJobDescriptionError": "",
        "certificateBadJudgement": "",
        "certificateHiddenLanguage": ""
    },
    "dateOfTermination": "",
    "address": {
        "city": "",
        "street": "",
        "number": "",
        "postcode": ""
    },
    "phone": "",
    "mail": "",
    "employer": {
        "firmname": "",
        "startOfContract": "",
        "employerAddress": {
            "employerCity": "",
            "employerStreet": "",
            "employerNumber": "",
            "employerPostcode": ""
        },
        "moreThanTenEmployees": "",
        "hasWorksCouncil": "",
        "hasWarningLetter": ""
    },
}));

export default formular;