import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import formular from "./classes/Formular";

import GeneralData from "./components/GeneralData";
import TerminationData from "./components/TerminationData";
import WarningInformations from "./components/WarningInformations";
import SpecialFeatures from "./components/SpecialFeatures";
import CertificateInformation from "./components/CertificateInformation";
import SalaryInformation from "./components/SalaryInformation";
import FileUpload from "./components/FileUpload.component";
import Finish from "./components/Finish.component";
import CostCalculator from "./components/CostCalculator";

import Team from "./pages/Team";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";

import COLORS from "./components/Colors";
import OtherIssue from "./components/OtherIssue";

var cleanFormular = function (formular = null) {
  if (formular == null) {
    return null;
  }

  console.log("formular", formular);
};

export default class App extends React.Component {
  constructor() {
    super();

    this.state = {
      formular: formular,
      navigationStatus: false,
      hideNavigation: false,
      scrollY: 0,
    };

    this.scrollPosition = 0;
    this.hide = false;

    this._formularHandler = this._formularHandler.bind(this);
    this._bgColorHandler = this._bgColorHandler.bind(this);
    this.closeNavigation = this.closeNavigation.bind(this);
    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.setHide = this.setHide.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  _bgColorHandler(color) {
    document.body.style = `background-color: ${COLORS[color]}`;
  }

  _formularHandler(props) {
    this.setState(
      (prevState) => ({
        formular: {
          ...prevState.formular,
          ...props,
        },
      }),
      cleanFormular(this.state.formular)
    );
  }

  click() {
    console.log(this.state.formular);
  }

  closeNavigation() {
    this.setState({ navigationStatus: false });
  }

  toggleNavigation() {
    this.setState({ navigationStatus: !this.state.navigationStatus });
  }

  _privacySettingHandler() {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer();
    }
  }
  setHide(bool) {
    this.setState({ hideNavigation: bool });
  }

  handleScroll() {
    var oldScrollPosition = this.scrollPosition;
    this.scrollPosition = window.scrollY;
    console.log(window.scrollY);
    if (window.scrollY > oldScrollPosition) {
      console.log("UP");
      this.hide = true;
      //this.setHide( true );
    } else {
      console.log("DOWN");
      this.hide = false;
      //this.setHide( false );
    }
    return;
    if (
      document.body.getBoundingClientRect().top >= 0 ||
      document.body.getBoundingClientRect().top > this.scrollPosition
    ) {
      this.setHide(false);
    } else {
      this.setHide(true);
    }
    this.scrollPosition = document.body.getBoundingClientRect().top;
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <Router>
        <Header />
        <main>
          <Route exact path={`${process.env.PUBLIC_URL}/`}>
            <Home
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
              cleanFormular={cleanFormular}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/faq`}>
            <Faq
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/team`}>
            <Team
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kontakt`}>
            <Contact
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/impressum`}>
            <Imprint
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/datenschutz`}>
            <Privacy
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/besonderheiten`}>
            <SpecialFeatures
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/abmahnungs-informationen`}>
            <WarningInformations
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/zeugnis-informationen`}>
            <CertificateInformation
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kuendigungs-informationen`}>
            <TerminationData
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/gehalts-informationen`}>
            <SalaryInformation
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/anderes-anliegen`}>
            <OtherIssue
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/daten-upload`}>
            <FileUpload
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/finish`}>
            <Finish _bgColorHandler={this._bgColorHandler} />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/allgemeine-daten`}>
            <GeneralData
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kostenrechner`}>
            <CostCalculatorPage
              formular={this.state.formular}
              _formularHandler={this._formularHandler}
              _bgColorHandler={this._bgColorHandler}
            />
          </Route>
        </main>
        <footer className="vrh-footer-navigation">
          <div>
            <p>
              &copy;{new Date().getFullYear()}{" "}
              <Link to="/">dein-jobhelfer.de</Link>
            </p>
          </div>
          <div>
            <nav>
              <ul>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/datenschutz`}>
                    Datenschutz
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/impressum`}>
                    Impressum
                  </Link>
                </li>
                <li>
                  <label className="vrh-gender-modal">
                    Gender-Hinweis
                    <input type="checkbox" id="" />
                    <span className="wrapper">
                      <span>
                        <span className="close">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </span>
                        Aus Gründen der besseren Lesbarkeit verwenden wir bei
                        Personenbezeichnungen und personenbezogenen Hauptwörtern
                        auf dieser Website die männliche Form also
                        beispielsweise "Ehepartner". Wir meinen mit den
                        entsprechende Begriffen immer alle Geschlechter im Sinne
                        der Gleichbehandlung. Die verkürzte Sprachform hat nur
                        redaktionelle Gründe und ist absolut wertfrei.
                      </span>
                    </span>
                  </label>
                </li>
                <li>
                  <a href="#" onClick={this._privacySettingHandler}>
                    Privacy Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </footer>
      </Router>
    );
  }
}

function Header() {
  const [navigationStatus, setNavigationStatus] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setIsHidden( false );
      } else if (y < window.scrollY) {
        setIsHidden( true );
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <header
          className={`vrh-main-navigation ${isHidden ? "is--hidden" : ""}`}
        >
          <div>
            <Link to={`${process.env.PUBLIC_URL}/`} className="vrh-logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                alt=""
                title=""
              />
            </Link>
          </div>
          <div>
            <input
              type="checkbox"
              id="vrh-nav-btn"
              checked={navigationStatus}
              readOnly
            />
            <label htmlFor="vrh-nav-btn" onClick={() => setNavigationStatus( !navigationStatus )}>
              <span></span>
              <span></span>
            </label>
            <nav>
              <ul>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    onClick={() => setNavigationStatus( false )}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/team`}
                    onClick={() => setNavigationStatus( false )}
                  >
                    Team
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/faq`}
                    onClick={() => setNavigationStatus( false )}
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/kontakt`}
                    onClick={() => setNavigationStatus( false )}
                  >
                    Kontakt
                  </Link>
                </li>
                {<li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/kostenrechner`}
                    onClick={() => setNavigationStatus( false )}
                  >
                    Kostenrechner
                  </Link>
                </li>}
              </ul>
              <p>
                <Link to={`${process.env.PUBLIC_URL}/datenschutz`}>
                  Datenschutz
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/impressum`}>
                  Impressum
                </Link>
                &copy; {new Date().getFullYear()} dein-scheidungshelfer.de
              </p>
            </nav>
          </div>
        </header>
  );
}

function CostCalculatorPage({ _bgColorHandler }) {
  _bgColorHandler("faq");

  return (
    <section className="vrh-calculator">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/scheidungshelfer_rechner.svg`}
        alt=""
        title=""
      />
      <h1>Willkommen bei Ihrem Kostenrechner</h1>
      <p>
        Füllen Sie die nachfolgenden Felder aus und erhalten so einen Überblick
        über die voraussichtlichen Kosten.
        <br />
        <br />
        Bei Rückfragen können Sie uns gerne unverbindlich kontaktieren.
      </p>
      <br />
      <CostCalculator />
    </section>
  );
}
