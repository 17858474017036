import React from "react";
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

export default function Team(props) {
    props._bgColorHandler('team');
    return (
        <section className="djh-team">
            <div className="djh-team__grid">
                <div>
                    <img className="djh-team__intro-img" src={`${process.env.PUBLIC_URL}/assets/images/team/teamfoto-1920x500.png`} alt="" title="" />
                </div>
                <div>
                    <h1>Das Team</h1>
                    <p>
                    Unser Expertenteam aus Fachanwälten für das Arbeits- und Sozialrecht übernimmt für Sie
                    die gesamte Abwicklung eines Arbeitsrechtsstreits und die Beratung. Durch unsere langjährige Erfahrung im Arbeitsrecht können Sie sicher sein, dass wir Sie in allen Belangen mit
                    der notwendigen Expertise beraten und Sie unterstützen. Wir begleiten und unterstützen
                    Sie kompetent und effizient.
                    </p>
                    <p>
                        Wir nehmen Ihnen den Aufwand der Auseinandersetzung ab, sodass Sie Ihre kostbare Zeit
                        anderen Dingen widmen können. Sie investieren nur einen kurzen Moment für die unkomplizierte Eingabe der notwendigen Daten. 
                    </p>
                    <p>
                        Vertrauen Sie sich uns an – bei uns sind Sie in den besten Händen. Wir freuen uns auf Sie! 
                    </p>
                    <p>
                        Herzlichst Ihre Rechtsanwälte
                    </p>
                    <p>
                        Rainer Lüders & Oliver Schunck<br/>
                        Leitung Team Job-Helfer<br/>
                        Gerling Schulz Lüders<br/>
                        Rechtsanwälte – Fachanwälte<br/>
                        Fachanwalt Arbeitsrecht<br/>
                        Fachanwalt Sozialrecht<br/>
                        <a href="https://rechtsanwalt-hennef.de/" target="_blank" title="rechtsanwalt-hennef.de">rechtsanwalt-hennef.de</a>
                    </p>
                    <Link to={`${process.env.PUBLIC_URL}/kontakt`} className="vrh-btn djh-team__contact-btn" title="">
                        Kontakt
                        <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="02-Meine-Daten---Seite-1" transform="translate(-747.000000, -882.000000)" fill="#252C32">
                                    <g id="Group-2" transform="translate(665.000000, 871.000000)">
                                        <g id="Group">
                                            <path d="M95.5612786,13.9001297 L92.7710155,11 L92.0609486,11.7367056 L94.706198,14.4811933 L82,14.4811933 L82,15.5188067 L94.706198,15.5188067 L92.0609486,18.2632944 L92.7710155,19 L95.5612786,16.0998703 C96.1462405,15.4922074 96.1462405,14.5077926 95.5612786,13.9001297 L95.5612786,13.9001297 Z" id="Style"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </Link>
                </div>

            </div>
            <Link to={`${process.env.PUBLIC_URL}/`} className="vrh-btn djh-team__start-btn" title="">
                Zum Jobhelfer
        <svg width="14px" height="8px" viewBox="0 0 14 8" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="02-Meine-Daten---Seite-1" transform="translate(-747.000000, -882.000000)" fill="#252C32">
                            <g id="Group-2" transform="translate(665.000000, 871.000000)">
                                <g id="Group">
                                    <path d="M95.5612786,13.9001297 L92.7710155,11 L92.0609486,11.7367056 L94.706198,14.4811933 L82,14.4811933 L82,15.5188067 L94.706198,15.5188067 L92.0609486,18.2632944 L92.7710155,19 L95.5612786,16.0998703 C96.1462405,15.4922074 96.1462405,14.5077926 95.5612786,13.9001297 L95.5612786,13.9001297 Z" id="Style"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </Link>
        </section>
    )
}